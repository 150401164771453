import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import history from '../routes/history';

import StyledSelect from '../components/StyledSelect';
import ReportTable from '../components/ReportTable';
import getManagersFromResponse from '../utils/reportUtils';
import { loadResponsesForReport } from '../actions/responses';


class ReportPage extends Component {
  constructor(props) {
    super(props);
    const initialFromDate = new Date();
    initialFromDate.setDate(1);

    const initialToDate = new Date();
    const {
      searchParams: {
        date_from,
        date_to,
      },
    } = this.props;

    this.state = {
      startInputDate: date_from || initialFromDate.toISOString().split('T')[0],
      endInputDate: date_to || initialToDate.toISOString().split('T')[0],
      reportTypeOptions: [
        { label: 'рекрутерам', value: 'byUsers' },
        { label: 'вакансиям', value: 'byVacancies' },
      ],
      reportType: { label: 'вакансиям', value: 'byVacancies' },
    };
  }

  componentDidMount = () => {
    const { loadResponsesForReport } = this.props;
    loadResponsesForReport(this.getFilters());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      startInputDate,
      endInputDate,
    } = this.state;

    const {
      startInputDate: prevStartInputDate,
      endInputDate: prevEndInputDate,
    } = prevState;

    if ((startInputDate !== prevStartInputDate)
    || (endInputDate !== prevEndInputDate)) {
      const searchParams = new URLSearchParams();
      searchParams.append('date_from', startInputDate);
      searchParams.append('date_to', endInputDate);
      history.push({ pathname: '/report', search: `?${searchParams.toString()}` });
    }
  }

  getFilters = () => {
    const { startInputDate, endInputDate } = this.state;
    return {
      created__date__gte: startInputDate,
      created__date__lte: endInputDate,
    };
  }

  onSort = (columnName) => {
    const { report, sortType } = this.state;
    const { byUsers } = report;

    const newSortType = sortType === 'ASC' ? 'DESC' : 'ASC';
    const sortFactor = sortType === 'ASC' ? 1 : -1;

    byUsers.sort((firstUser, secondUser) => {
      if (columnName === 'created') {
        return (firstUser[columnName] < secondUser[columnName] ? -1 : 1) * sortFactor;
      }
      return (firstUser.hiringCounter[columnName] < secondUser.hiringCounter[columnName] ? -1 : 1) * sortFactor;
    });

    this.setState({
      report: { ...report, byUsers: [...byUsers] },
      sortType: newSortType,
    });
  }

  createReport = () => {
    const { responses, vacancies } = this.props;
    const { reportType: { value: reportTypeValue } } = this.state;

    const report = {
      created: 0,
      [reportTypeValue]: [],
      byTemplates: {},
    };

    report[reportTypeValue] = reportTypeValue === 'byVacancies'
      ? report[reportTypeValue] = vacancies
        .filter(({ hidden }) => !hidden)
        .map(({ id, title }) => ({
          id, title, created: 0, hiringCounter: {},
        }))
      : report[reportTypeValue] = getManagersFromResponse(responses).map(({ id, first_name, last_name }) => ({
        id, first_name, last_name, created: 0, hiringCounter: {},
      }));

    const reportWithHiringTemplateStats = report[reportTypeValue].reduce((acc, { id }, index) => ({
      ...this.mapResponsesByHiringTemplates(acc, reportTypeValue, index, id),
    }), report);


    const updatedReport = responses.reduce((acc, { creator, vacancy }) => {
      acc.created += 1;

      const index = reportTypeValue === 'byVacancies'
        ? acc[reportTypeValue].findIndex(({ id }) => vacancy === id)
        : creator && acc[reportTypeValue].findIndex(({ id }) => creator.id === id);

      if (typeof index === 'number' && index >= 0) {
        acc[reportTypeValue][index].created += 1;
      }

      return acc;
    }, reportWithHiringTemplateStats);

    return updatedReport;
  }

  mapResponsesByHiringTemplates = (report, reportTypeValue, index, reportTypeId) => {
    const { hiringTemplatesStates, responses } = this.props;
    const updatedReport = { ...report };

    hiringTemplatesStates.slice(1).forEach(({ name }) => {
      if (!report.byTemplates.hasOwnProperty(name)) {
        updatedReport.byTemplates[name] = 0;
      }

      updatedReport[reportTypeValue][index].hiringCounter[name] = 0;

      responses.forEach(({ response_changes, vacancy }) => {
        const firstHiringTemplateStatus = hiringTemplatesStates[0].name;
        const indexIfResponseWasReloaded = response_changes
          .map(({ status }) => status)
          .lastIndexOf(firstHiringTemplateStatus);

        const isResponseAllowable = response_changes.find(
          ({ status, user }, responseChangeIndex) => {
            if (responseChangeIndex > indexIfResponseWasReloaded) {
              const id = reportTypeValue === 'byVacancies' ? vacancy : user.id;
              if (status === name && id === reportTypeId) {
                return true;
              }
            }
            return false;
          },
        );

        if (isResponseAllowable) {
          updatedReport.byTemplates[name] += 1;
          updatedReport[reportTypeValue][index].hiringCounter[name] += 1;
        }
      });
    });

    return updatedReport;
  }

  onInputChange = (newDate, name) => {
    const dateValue = newDate.toISOString().split('T')[0];
    this.setState({ [`${name}InputDate`]: dateValue });
  }

  onDateSelect = (newDate, name) => {
    const { loadResponsesForReport } = this.props;
    const dateValue = newDate.toISOString().split('T')[0];
    this.setState({ [`${name}InputDate`]: dateValue },
      () => loadResponsesForReport(this.getFilters()));
  }

  onReportTypeSelect = (newValue) => {
    this.setState({ reportType: newValue });
  }

  onInputDateFinished = () => {
    const { startInputDate: startValue, endInputDate: endValue } = this.state;
    const { loadResponsesForReport } = this.props;

    const [startInputDate] = new Date(startValue).toISOString().split('T');
    const [endInputDate] = new Date(endValue).toISOString().split('T');

    if (endInputDate < startInputDate) {
      this.setState({
        startInputDate: endInputDate,
        endInputDate: startInputDate,
      }, () => loadResponsesForReport(this.getFilters()));
    } else {
      this.setState({
        startInputDate,
        endInputDate,
      }, () => loadResponsesForReport(this.getFilters()));
    }
  }

  render() {
    const {
      startInputDate,
      endInputDate,
      reportTypeOptions,
      reportType,
    } = this.state;

    const {
      hiringTemplatesStates,
      activeTeamShortId,
      responses,
    } = this.props;

    const report = this.createReport();

    return (
      <div className="responses-wrap section-grey report-page">
        <Helmet>
          <title>Отчётность | Jobs Widget</title>
        </Helmet>
        <div className="page-header-container">
          <h1 className="main-page-title">Отчётность по</h1>
          <StyledSelect
            options={reportTypeOptions}
            value={reportType}
            className="report-page-filter-type-selector"
            onChange={newValue => this.onReportTypeSelect(newValue)}
          />
        </div>
        <div className="responses-page-filter-container">
          <div className="date-filter-container">
            <h4>
              Период:
            </h4>
            <div className="date-filter-container-decorator">
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                className="form-control date-response-filter"
                selected={new Date(startInputDate)}
                onChange={newDate => this.onInputChange(newDate, 'start')}
                onBlur={() => this.onInputDateFinished('start')}
                onSelect={newDate => this.onDateSelect(newDate, 'start')}
              />
              <span className="date-filter-dash">
                &#8212;
              </span>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                className="form-control date-response-filter"
                selected={new Date(endInputDate)}
                onChange={newDate => this.onInputChange(newDate, 'end')}
                onBlur={() => this.onInputDateFinished('end')}
                onSelect={newDate => this.onDateSelect(newDate, 'end')}
              />
            </div>
          </div>
        </div>
        <ReportTable
          report={report}
          responses={responses}
          hiringTemplatesStates={hiringTemplatesStates}
          activeTeamShortId={activeTeamShortId}
          startInputDate={new Date(startInputDate)}
          endInputDate={new Date(endInputDate)}
          onSort={this.onSort}
          reportType={reportType.value}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    responses: { responses },
    entities: { teams },
    hiringTemplatesStates,
    app: { activeTeamShortId, isLoading, activeTeam },
  } = state;
  const { location } = ownProps;
  const locationSearch = new URLSearchParams(location.search);
  const searchParams = {};
  searchParams.status = +locationSearch.get('status') || 0;
  searchParams.date_from = locationSearch.get('date_from') || null;
  searchParams.date_to = locationSearch.get('date_to') || null;

  const { vacancies } = teams[activeTeam];
  const notArchievedVacancies = vacancies.filter(({ hidden }) => !hidden);
  const notArchievedResponses = responses.filter(({ vacancy }) => (
    notArchievedVacancies.find(({ id }) => id === vacancy)));

  return {
    vacancies: notArchievedVacancies,
    responses: notArchievedResponses,
    hiringTemplatesStates,
    searchParams,
    activeTeamShortId,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  loadResponsesForReport: filters => dispatch(loadResponsesForReport(filters)),
});

ReportPage.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  hiringTemplatesStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchParams: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  loadResponsesForReport: PropTypes.func.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
