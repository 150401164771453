import store from '../store';
import axios from '../api';
import {
  CHECK_USER_AUTHORIZATION_FAIL,
  CHECK_USER_AUTHORIZATION_REQUEST,
  CHECK_USER_AUTHORIZATION_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  LOAD_INITIAL_DATA_FAIL,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  LOAD_INITIAL_DATA_SUCCESS,
  CHANGE_ACTIVE_TEAM_SUCCESS,
  CHANGE_ACTIVE_TEAM_REQUEST,
  CHANGE_ACTIVE_TEAM_FAIL,
  RESET_API_STATUS,
  RESET_ERROR,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAIL,
  CHANGE_PASSWORD_AFTER_RESTORE_REQUEST,
  CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS,
  CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
  EDIT_USER_SETTINGS_REQUEST,
  EDIT_USER_SETTINGS_SUCCESS,
  EDIT_USER_SETTINGS_FAIL,
  CHANGE_ACTIVE_TEAM_ON_INIT_REQUEST,
  CHANGE_ACTIVE_TEAM_ON_INIT_SUCCESS,
  CHANGE_ACTIVE_TEAM_ON_INIT_FAIL,
} from '../constants/appConst';

import { setAuthorizationToken } from '../utils';
import history from '../routes/history';
import { loadActiveTeam, loadAllTeams } from './teams';
import { getHiringTemplatesStates } from './hiringTemplatesStates';
import { getFieldSets } from './fieldSets';
import { getFields } from './fields';
import { getQuestions } from './questions';
import { loadResponses } from './responses';


export const loadInitialData = () => async (dispatch) => {
  try {
    const { app: { activeTeam } } = store.getState();
    if (activeTeam) {
      await Promise.all([
        dispatch(loadAllTeams()),
        dispatch(loadActiveTeam()),
        dispatch(loadResponses()),
        dispatch(getHiringTemplatesStates()),
        dispatch(getFields()),
        dispatch(getFieldSets()),
        dispatch(getQuestions()),
      ]);
    }
    dispatch({ type: LOAD_INITIAL_DATA_SUCCESS });
  } catch (error) {
    dispatch({ type: LOAD_INITIAL_DATA_FAIL, error });
  }
};

export const checkUserAuthorization = (token) => {
  setAuthorizationToken(token);
  return {
    name: 'user',
    types: [CHECK_USER_AUTHORIZATION_REQUEST, CHECK_USER_AUTHORIZATION_SUCCESS, CHECK_USER_AUTHORIZATION_FAIL],
    callApi: () => axios.get('/me/'),
  };
};

export const loginUser = (email, password) => ({
  types: [LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
  callApi: () => axios.post('/auth/login/', { email, password }),
  afterSuccessCall: (response, dispatch) => {
    const { token } = response;
    localStorage.setItem('token', token);
    setAuthorizationToken(token);
    dispatch(loadInitialData());
  },
});

export const logoutUser = () => ({
  types: [LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL],
  callApi: () => {
    const response = axios.post('/auth/logout/');
    localStorage.removeItem('token');
    setAuthorizationToken(false);
    return response;
  },
});

export const signUpUser = (first_name, last_name, email, password) => ({
  name: 'user',
  types: [REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAIL],
  callApi: () => axios.post('/auth/users/', {
    first_name,
    last_name,
    email,
    password,
  }),
  afterSuccessCall: () => history.push(`/signup/success?email=${email}`),
});

export const restorePassword = email => ({
  types: [RESTORE_PASSWORD_REQUEST, RESTORE_PASSWORD_SUCCESS, RESTORE_PASSWORD_FAIL],
  callApi: () => axios.post('/auth/password/reset/', { email }),
  afterSuccessCall: () => history.push(`/restore/success?email=${email}`),
});

export const changePasswordAfterRestore = (new_password, re_new_password, uid, token) => ({
  types: [
    CHANGE_PASSWORD_AFTER_RESTORE_REQUEST,
    CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS,
    CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
  ],
  callApi: () => axios.post('/auth/password/reset/confirm/', {
    new_password,
    re_new_password,
    uid,
    token,
  }),
  afterSuccessCall: () => history.push('/login'),
});

export const editProfile = (id, user) => ({
  name: 'user',
  types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAIL],
  callApi: () => axios.patch(`/auth/users/${id}/`, user),
});

export const editUserSettings = (id, settings) => ({
  name: 'user',
  types: [EDIT_USER_SETTINGS_REQUEST, EDIT_USER_SETTINGS_SUCCESS, EDIT_USER_SETTINGS_FAIL],
  callApi: () => axios.patch(`/auth/users/${id}/`, { settings }),
});

export const changeActiveTeam = (teamId, newPathname) => ({
  name: 'team',
  types: [CHANGE_ACTIVE_TEAM_REQUEST, CHANGE_ACTIVE_TEAM_SUCCESS, CHANGE_ACTIVE_TEAM_FAIL],
  callApi: () => axios.post('/teams/change_active/', { id: teamId }),
  afterSuccessCall: () => history.push(newPathname),
});
export const changeActiveTeamOnInit = teamId => ({
  name: 'team',
  types: [CHANGE_ACTIVE_TEAM_ON_INIT_REQUEST, CHANGE_ACTIVE_TEAM_ON_INIT_SUCCESS, CHANGE_ACTIVE_TEAM_ON_INIT_FAIL],
  callApi: () => axios.post('/teams/change_active/', { id: teamId }),
});

export const changeAndUpdateActiveTeam = (teamId, newPathname) => (dispatch) => {
  dispatch(changeActiveTeam(teamId, newPathname))
    .then(() => dispatch(loadInitialData()));
};

export const resetApiStatus = name => ({
  type: RESET_API_STATUS,
  name,
});

export const resetError = name => ({
  type: RESET_ERROR,
  name,
});

export const getCountries = () => ({
  name: 'countries',
  types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL],
  callApi: () => axios.get('/countries/'),
});

export const getCities = (country, city) => axios.get('/cities/', { params: { country, city } });

export const getAddresses = (country, city, address) => axios.get('/addresses/', {
  params: { country, city, address },
});
