import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import PaymentForm from '../forms/PaymentForm';
import PromoForm from '../forms/PromoForm';
import { applyCoupon } from '../actions/payment';
import CompanyDetailsModal from './CompanyDetailsModal';


const PaymentPage = (props) => {
  const handlePromoForm = (values) => {
    const { applyCoupon } = props;
    applyCoupon(values.promo);
  };

  return (
    <div className="payment-page section-grey">
      <Helmet>
        <title>Оплатить тариф | Jobs Widget</title>
      </Helmet>
      <div className="page-header-container">
        <div className="title">Оплатить тариф</div>
      </div>
      <div className="content-container content-container_for-page-payment">
        <div className="payment-form">
          <PaymentForm />
          <PromoForm onSubmit={handlePromoForm} />
        </div>
        <div className="payment-info">
          <div className="payment-info-top">
            <p className="payment-info-text">
              Оплатить тариф можно с помощью сервиса InvoiceBox:
            </p>
            <ul className="payment-info-list">
              <li className="payment-info-list-item">
                <span className="payment-info-list-item-title">Банковские карты</span>
                <span className="payment-info-list-item-text">(Visa, MasterCard, МИР)</span>
              </li>
              <li className="payment-info-list-item">
                <span className="payment-info-list-item-title">Электронные деньги</span>
                <span className="payment-info-list-item-text">(QIWI, WebMoney)</span>
              </li>
              <li className="payment-info-list-item">
                <span className="payment-info-list-item-title">Терминалы оплаты</span>
                <span className="payment-info-list-item-text">(Элекснет, Новоплат, Уникасса и другие)</span>
              </li>
            </ul>
            <p className="payment-info-text-email">
              Для оплаты сервиса как юридическое лицо напишите нам на
              {' '}
              <span className="light-green">
                <a href="mailto:payment@jobswidget.me">payment@jobswidget.me</a>
              </span>
            </p>
          </div>
          <CompanyDetailsModal />
        </div>
      </div>
    </div>
  );
};

PaymentPage.propTypes = {
  applyCoupon: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  applyCoupon: promo => dispatch(applyCoupon(promo)),
});

export default connect(null, mapDispatchToProps)(PaymentPage);
