import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { reset } from 'redux-form';

import HiringTemplateForm from '../forms/HiringTemplateForm';
import { editHiringTemplate, createHiringTemplate } from '../actions/teams';
import { createOption } from '../utils';
import Breadcrumbs from '../components/Breadcrumbs';


const EditHiringTemplate = (props) => {
  const {
    createHiringTemplate,
    editHiringTemplate,
    template,
    team,
    team: { name },
    activeTeamShortId,
    customHiringStates,
  } = props;

  const formatTemplate = ({ name, actions }) => {
    const fromStatesSet = new Set(actions.map(action => action.from_state));
    const formattedActions = [...fromStatesSet].map(state => ({
      from_state: createOption(state),
      actions: actions
        .filter(action => action.from_state === state)
        .map(({ to_state, button_name, sort_order }) => ({
          to_state: createOption(to_state),
          button_name,
          sort_order,
        })),
    }));

    const initialHiringStates = customHiringStates.reduce(
      (accum, { id, name }) => ({ ...accum, [`state-name-${id}`]: name }),
      {},
    );

    return {
      name,
      actionsSetup: formattedActions,
      team: team.id,
      ...initialHiringStates,
    };
  };

  const reverseFormatTemplate = ({ name, actionsSetup }) => {
    const formattedActions = [];
    actionsSetup.forEach(({ from_state, actions }) => actions
      .forEach(({ to_state, button_name, sort_order }) => formattedActions
        .push({
          button_name,
          sort_order,
          from_state: from_state.value,
          to_state: to_state.value,
        })));
    return {
      actions: formattedActions,
      initial_state: formattedActions[0].from_state,
      name,
    };
  };

  const handleSubmit = (values) => {
    const { resetEditHiringStateForm } = props;
    if (template) {
      editHiringTemplate({ ...reverseFormatTemplate(values), id: template.id }, resetEditHiringStateForm);
    } else {
      createHiringTemplate(reverseFormatTemplate(values));
    }
  };

  const getInitialValues = () => {
    const initialValuesForNewTemplate = {
      actionsSetup: [
        {
          actions: [{}],
        },
      ],
      team: team.id,
    };
    return template
      ? formatTemplate(template)
      : initialValuesForNewTemplate;
  };

  const breadcrumbName = template ? template.name : 'Создать сценарий найма';
  const breadcrumbLink = template
    ? `/teams/${activeTeamShortId}/templates#${template.id}`
    : `/teams/${activeTeamShortId}/templates`;
  const breadcrumbs = [
    { title: 'Настройка найма', link: breadcrumbLink },
    { title: breadcrumbName, link: '' },
  ];
  const title = template
    ? `${template.name} редактирование шаблона найма | Jobs Widget`
    : 'Создать сценарий найма | Jobs Widget';

  const initialValues = getInitialValues();
  return (
    <div className="template-editing">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h2 className="template-editing-title">
        Редактирование шаблона найма для компании
        {' '}
        <span>{name}</span>
      </h2>
      <HiringTemplateForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

EditHiringTemplate.defaultProps = {
  template: null,
};

EditHiringTemplate.propTypes = {
  team: PropTypes.object.isRequired,
  template: PropTypes.object,
  createHiringTemplate: PropTypes.func.isRequired,
  editHiringTemplate: PropTypes.func.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  resetEditHiringStateForm: PropTypes.func.isRequired,
  customHiringStates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    app: { activeTeam, activeTeamShortId },
    entities: { teams },
    hiringTemplatesStates,
  } = state;
  const team = teams[activeTeam] || {};
  const hiringTemplateId = +ownProps.match.params.templateId;
  const template = team.hiring_templates
    && team.hiring_templates.find(template => template.id === hiringTemplateId);
  const customHiringStates = hiringTemplatesStates.filter(({ is_custom }) => is_custom);
  return {
    team,
    template,
    activeTeamShortId,
    customHiringStates,
  };
};

const mapDispatchToProps = dispatch => ({
  createHiringTemplate: template => dispatch(createHiringTemplate(template)),
  editHiringTemplate: (template, resetForm) => dispatch(editHiringTemplate(template, resetForm)),
  resetEditHiringStateForm: () => dispatch(reset('editHiringTemplate')),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditHiringTemplate),
);
