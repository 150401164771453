import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';

import { LinkContainer } from 'react-router-bootstrap';
import VacanciesPageFilterContainer from '../components/VacanciesPageFilterContainer';
import VacanciesList from '../components/VacanciesList';
import {
  deleteVacancy,
  editVacancy,
  editVacancies,
  deleteVacancies,
  restrictPublishVacancy,
  showInAllVacancies,
  getHhVacancies,
} from '../actions/teams';
import history from '../routes/history';
import { resetSelection } from '../actions/selection';


class VacanciesPage extends React.Component {
  constructor(props) {
    super(props);
    const { vacancies } = this.props;

    this.state = {
      showFilter: false,
      filteredVacancies: vacancies,
      sortValue: { label: 'по дате', value: 'date' },
      onlyPublished: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filteredVacancies, onlyPublished, sortValue,
    } = this.state;
    const { location: { pathname, search }, vacancies } = this.props;
    const searchParams = new URLSearchParams(search);

    if (
      prevState.filteredVacancies.length !== filteredVacancies.length
      || prevState.onlyPublished !== onlyPublished
      || prevState.sortValue !== sortValue
    ) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }

    if (prevProps.vacancies !== vacancies) {
      this.handleFilterChange(vacancies);
    }
  }

  handleFilterChange = (filteredVacancies) => {
    this.setState({
      filteredVacancies,
    });
  };

  handleFilterChangeArchive = (filteredVacancies) => {
    this.setState({
      filteredVacancies,
    });
  };

  handleSortChange = (sortValue) => {
    this.setState({
      sortValue,
    });
  };

  sortFunction = (a, b) => {
    const { sortValue } = this.state;
    if (sortValue.value === 'responses') {
      return b.responses_amount - a.responses_amount;
    }
    return Date.parse(b.publish_date) - Date.parse(a.publish_date);
  };

  onCheckboxChange = (event) => {
    const { target } = event;
    this.setState({
      onlyPublished: target.checked,
    });
  };

  render() {
    const {
      vacancies,
      editVacancies,
      deleteVacancies,
      editVacancy,
      deleteVacancy,
      teamId,
      lastEditFailedVacancy,
      location,
      activeTeamShortId,
      resetSelection,
      paymentStatus,
      restrictPublishVacancy,
      responses,
      showInAllVacancies,
      includeInAllVacancies,
      isLoading,
    } = this.props;

    const {
      showFilter,
      filteredVacancies,
      sortValue,
      onlyPublished,
    } = this.state;

    const importHHModalButton = (
      <button
        type="button"
        className="button button_color_dark-blue vacancies-list__button"
      >
        Импортировать вакансии с hh.ru
      </button>
    );

    const filteredAndSortedVacancies = onlyPublished
      ? filteredVacancies.filter(vacancy => vacancy.is_published).sort(this.sortFunction).slice()
      : filteredVacancies.sort(this.sortFunction).slice();

    return (
      <div className="vacancies-wrap section-grey">
        <Helmet>
          <title>Вакансии | Jobs Widget</title>
        </Helmet>
        <div className="page-header-container">
          <h1 className="main-page-title">
            Список вакансий
          </h1>
          <div
            className="vacancies-filter-open-box"
            onClick={() => {
              this.setState({ showFilter: !showFilter });
            }}
          >
            <FontAwesomeIcon
              className="item-icon vacancies-filter-icon"
              icon="filter"
            />
            Показать фильтры
            { showFilter ? (
              <FontAwesomeIcon
                className="item-icon vacancies-filter-chevron-icon"
                icon="chevron-up"
              />
            ) : (
              <FontAwesomeIcon
                className="item-icon vacancies-filter-chevron-icon"
                icon="chevron-down"
              />
            )}
          </div>
          <Link
            to={`/teams/${activeTeamShortId}/create-vacancy`}
            hidden={teamId === null}
            className="add-vacancy-button"
          >
            <button type="button" className="button button_plus">
              <span className="button-icon">+</span>
              <span className="button-text">Создать вакансию</span>
            </button>
          </Link>
        </div>
        <Collapse
          isOpen={showFilter}
        >
          <VacanciesPageFilterContainer
            vacancies={vacancies}
            filteredVacancies={filteredAndSortedVacancies}
            handleFilterChange={this.handleFilterChange}
            pageName="VacanciesPage"
          />
        </Collapse>
        <VacanciesList
          editVacancy={editVacancy}
          editVacancies={editVacancies}
          showInAllVacancies={showInAllVacancies}
          includeInAllVacancies={includeInAllVacancies}
          deleteVacancies={deleteVacancies}
          deleteVacancy={deleteVacancy}
          teamId={teamId}
          lastEditFailedVacancy={lastEditFailedVacancy}
          items={filteredAndSortedVacancies}
          location={location}
          responses={responses}
          resetSelection={resetSelection}
          onlyPublished={onlyPublished}
          handleSortChange={this.handleSortChange}
          onCheckboxChange={this.onCheckboxChange}
          sortValue={sortValue}
          allVacancies={vacancies}
          paymentStatus={paymentStatus}
          restrictPublishVacancy={restrictPublishVacancy}
          shortId={activeTeamShortId}
          isLoading={isLoading}
        />
        <LinkContainer to="vacancies/import">
          {importHHModalButton}
        </LinkContainer>
      </div>
    );
  }
}

VacanciesPage.defaultProps = {
  teamId: null,
  vacancies: [],
  lastEditFailedVacancy: null,
};

VacanciesPage.propTypes = {
  teamId: PropTypes.number,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  editVacancy: PropTypes.func.isRequired,
  editVacancies: PropTypes.func.isRequired,
  deleteVacancies: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  lastEditFailedVacancy: PropTypes.number,
  resetSelection: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string.isRequired,
  restrictPublishVacancy: PropTypes.func.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  showInAllVacancies: PropTypes.func.isRequired,
  includeInAllVacancies: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    api: { importHhVacanciesStatus },
    app: {
      activeTeam, activeTeamShortId, lastEditFailedVacancy, isLoading,
    },
    entities: { teams },
    responses: { responses = [] },
  } = state;
  const team = teams[activeTeam] || {};
  const { hh_employer_id = null, hhVacancies = null } = team;
  return {
    hhEmployerId: hh_employer_id,
    teamId: activeTeam,
    vacancies: team.vacancies && team.vacancies
      .filter(vacancy => !vacancy.deleted)
      .filter(vacancy => !vacancy.hidden),
    responses,
    includeInAllVacancies: !!(team && team.vacancies_published_at),
    lastEditFailedVacancy,
    importHhVacanciesStatus,
    activeTeamShortId,
    hhVacancies,
    paymentStatus: team.payment_status,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  editVacancy: vacancy => dispatch(editVacancy(vacancy, false)),
  editVacancies: vacancy => dispatch(editVacancies(vacancy, false)),
  deleteVacancies: vacancy => dispatch(deleteVacancies(vacancy, false)),
  deleteVacancy: vacancy => dispatch(deleteVacancy(vacancy)),
  resetSelection: name => dispatch(resetSelection(name)),
  restrictPublishVacancy: id => dispatch(restrictPublishVacancy(id)),
  showInAllVacancies: (teamId, includeInAllVacancies) => dispatch(
    showInAllVacancies(teamId, includeInAllVacancies),
  ),
  getHhVacancies: (teamHhId, activeTeam) => dispatch(getHhVacancies(teamHhId, activeTeam)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacanciesPage);
