import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderField from '../forms/render/renderField';
import ConfirmModal from './ConfirmModal';
import StatusMessage from '../containers/StatusMessage';
import ErrorMessage from './ErrorMessage';
import { manageHiringStateError } from '../actions/hiringTemplatesStates';


class EditHiringState extends React.Component {
  constructor(props) {
    super(props);
    const { id, name } = props;
    const isNew = id === -1;
    this.state = {
      isNew,
      id,
      fieldName: name || '',
    };
  }

  createHandler = (id) => {
    const { createItem } = this.props;
    const { fieldName } = this.state;
    createItem({ name: fieldName, id, tempId: id });
  }

  editHandler = () => {
    const { editItem } = this.props;
    const { id, fieldName } = this.state;
    editItem({ id, name: fieldName });
  }

  deleteHandler = () => {
    const { deleteItem } = this.props;
    const { id } = this.state;
    deleteItem({ id });
  }

  cancelCreateHandler = () => {
    const { cancelCreateItem, tempId } = this.props;
    cancelCreateItem(tempId);
  }

  onNameChange = evt => this.setState({ fieldName: evt.target.value });

  render() {
    const {
      labelName,
      name,
      isLabelNeed,
      newLabelName,
      tempId,
      templates,
      requestStatus,
      manageHiringStateError,
      stateNameIds,
    } = this.props;
    const { fieldName } = this.state;
    const { id, isNew } = this.state;
    const label = isNew ? newLabelName : labelName;
    const templateWithThisStateNames = templates.filter((template) => {
      const containedAction = template.actions
        .filter(action => action.from_state === fieldName || action.to_state === fieldName);
      return containedAction.length > 0;
    }).map(template => template.name);
    const currentId = id !== -1 ? id : tempId;
    const isFieldAlreadyExistCreate = stateNameIds.filter(state => state.name === fieldName).length > 0 && isNew;
    const isFieldAlreadyExistEdit = stateNameIds
      .filter(state => state.name === fieldName && currentId !== state.id).length > 0 && !isNew;
    const deleteStateMessage = templateWithThisStateNames.length > 0
      ? `Данное состояние используется в следующих наборах: ${templateWithThisStateNames.join(', ')}
      . Чтобы удалить состояние, уберите его из начальных и конечных состояний шаблонов.`
      : 'Вы действительно хотите удалить данное поле?';
    const errorCreateMessage = isFieldAlreadyExistCreate
      ? 'Данное состояние уже существует, вы не можете его добавить'
      : '';
    const errorEditMessage = isFieldAlreadyExistEdit
      ? 'Данное состояние уже существует, вы не можете использовать данное название'
      : '';
    const deleteDisabled = templateWithThisStateNames.length > 0;
    return (
      <div className="create-hiring-state-layout">
        <div className="create-hiring-state-layout-main">
          <div className="create-hiring-state-input">
            <Field
              name={`state-name-${currentId}`}
              label={isLabelNeed ? label : ''}
              placeholder={name}
              type="text"
              onChange={this.onNameChange}
              component={renderField}
            />
          </div>
          <div className="buttons-circle-container">
            {!isNew
              ? (
                <React.Fragment>
                  <button
                    type="button"
                    className="button-circle button-circle_color_light-green"
                    onClick={errorEditMessage
                      ? () => manageHiringStateError(currentId, errorEditMessage)
                      : this.editHandler}
                  >
                    <FontAwesomeIcon
                      icon="check"
                    />
                  </button>
                  <ConfirmModal
                    modalAction={this.deleteHandler}
                    type="circle-button-icon"
                    buttonText="Удалить поле"
                    headerText={deleteStateMessage}
                    disabled={deleteDisabled}
                  />
                </React.Fragment>)
              : (
                <React.Fragment>
                  <button
                    type="button"
                    className="button-circle button-circle_color_light-green"
                    onClick={errorCreateMessage
                      ? () => manageHiringStateError(currentId, errorCreateMessage)
                      : () => this.createHandler(currentId)
                    }
                  >
                    <FontAwesomeIcon
                      icon="check"
                    />
                  </button>
                  <button
                    type="button"
                    className="button-circle"
                    onClick={this.cancelCreateHandler}
                  >
                    <FontAwesomeIcon
                      icon="trash-alt"
                    />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
        <StatusMessage
          status={requestStatus['editHiringState-descripion']
          && requestStatus['editHiringState-descripion'].id === currentId ? 'editHiringState' : ''}
          successMessage="Состояние успешно изменено"
          errorMessage="Произошла ошибка, состояние не изменено"
        />
        <StatusMessage
          status={requestStatus['deleteHiringState-descripion']
          && requestStatus['deleteHiringState-descripion'].id === currentId ? 'deleteHiringState' : ''}
          errorMessage="Произошла ошибка, состояние не удалено"
        />
        <StatusMessage
          errorMessage={errorCreateMessage}
          status={requestStatus['createHiringState-descripion']
          && requestStatus['createHiringState-descripion'].id === currentId ? 'createHiringState' : ''}
        />
        {isFieldAlreadyExistCreate && <ErrorMessage errorName="managmentHiringState" />}
        {isFieldAlreadyExistEdit && <ErrorMessage errorName="managmentHiringState" />}
      </div>);
  }
}

EditHiringState.defaultProps = {
  labelName: 'название',
  createItem: () => {},
  deleteItem: () => {},
  editItem: () => {},
  name: '',
  isLabelNeed: true,
  id: -1,
  newLabelName: 'название',
  cancelCreateItem: () => {},
  tempId: '',
  requestStatus: {},
};

EditHiringState.propTypes = {
  labelName: PropTypes.string,
  createItem: PropTypes.func,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
  name: PropTypes.string,
  isLabelNeed: PropTypes.bool,
  id: PropTypes.number,
  newLabelName: PropTypes.string,
  cancelCreateItem: PropTypes.func,
  tempId: PropTypes.string,
  requestStatus: PropTypes.object,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  manageHiringStateError: PropTypes.func.isRequired,
  stateNameIds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { app: { activeTeamShortId } } = state;
  const { entities: { teams } } = state;
  const { hiringTemplatesStates } = state;
  const teamsKeys = Object.keys(teams);
  const currentTeam = teams[teamsKeys.find(key => teams[key].short_id === activeTeamShortId)];
  const allStateNameAndId = hiringTemplatesStates.map((state) => {
    const { name, id } = state;
    return { name, id };
  });
  return {
    templates: currentTeam.hiring_templates,
    stateNameIds: allStateNameAndId,
  };
};

const mapDispatchToProps = dispatch => ({
  manageHiringStateError: (stateId, errorMessage) => dispatch(manageHiringStateError(stateId, errorMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditHiringState);
