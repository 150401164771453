import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import {
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import history from '../routes/history';
import ConfirmModal from '../components/ConfirmModal';

import {
  addComment,
  editComment,
  deleteComment,
  editResponse,
  deleteResponse,
  changeStatus,
  markResponseAsViewed,
  loadResponse,
  loadResponses,
  moveResponse,
} from '../actions/responses';
import ApplicantStatus from '../components/ApplicantStatus';
import CommentsContainer from '../components/CommentsContainer';
import ResponseForm from '../forms/ResponseForm';
import StatusMessage from './StatusMessage';
import ResponseQuestionsList from '../components/ResponseQuestionsList';
import Breadcrumbs from '../components/Breadcrumbs';
import ResponseData from '../components/ResponseData';
import ResponseHistory from '../components/ResponseHistory';
import NotFoundPage from '../components/NotFoundPage';
import withLoading from '../components/withLoading';


class ResponseReview extends React.Component {
  state = {
    isEditable: false,
  };

  componentDidMount() {
    const {
      markResponseAsViewed,
      responses,
      responseId,
      loadResponse,
      errors,
    } = this.props;

    const response = (responses && responses.find(
      response => response.id === responseId,
    )) || null;

    const isError = errors.hasOwnProperty('getResponseError');

    if (response) {
      this.onResponseGetting(response);
      if (!response.viewed) {
        markResponseAsViewed(response);
      }
    } else if (!isError) {
      loadResponse(responseId);
    }
  }

  componentDidUpdate(prevProps) {
    const { responseId, responses } = this.props;
    if (prevProps.responses !== responses) {
      const response = responses.find(
        response => response.id === responseId,
      );
      this.onResponseGetting(response);
    }
  }

  onResponseGetting(response) {
    this.setState({ response });
  }

  handleSubmit = (values) => {
    const { editResponse } = this.props;
    const { response: { id } } = this.state;
    editResponse({ id, ...values });
  };

  handleDelete = () => {
    const { deleteResponse, vacancy: { id: vacancyId } } = this.props;
    const { response: { id } } = this.state;
    deleteResponse(id, vacancyId);
  };

  changeStatus = (status) => {
    const { changeStatus } = this.props;
    const { response: { id } } = this.state;
    changeStatus({ id, status });
  };

  toggleIsEditable = () => {
    this.setState(state => ({ isEditable: !state.isEditable }));
  };

  render() {
    const { isEditable, response } = this.state;
    const {
      vacancy,
      userId,
      hiringTemplate,
      moveResponse,
      addComment,
      editComment,
      deleteComment,
      submitForm,
      questions,
      teamAdmins,
      errors,
      vacancies,
      match: { params: { shortId, vacancyId } },
    } = this.props;

    const isError = errors.hasOwnProperty('getResponseError');
    if (isError || !response || !vacancy) {
      return <NotFoundPage />;
    }

    const { response_changes, comments } = response;
    const { first_name, last_name } = response.fields;
    const name = last_name ? `${first_name} ${last_name}` : first_name;

    const breadcrumbs = [
      { title: 'Вакансии', link: `/teams/${shortId}/vacancies` },
      { title: 'Отклики', link: `/teams/${shortId}/vacancies/responses` },
      { title: vacancy.title, link: `/teams/${shortId}/vacancies/${vacancyId}/responses#${response.id}` },
      { title: name, link: '' },
    ];
    const title = `${name} ${vacancy.title} | Jobs Widget`;

    return (
      <div className="response-page">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row className="justify-content-md-between">
          <Col md="12" lg="12" className="response-top">
            <button
              type="button"
              className="response-top__back-button"
              onClick={() => history.push(`/teams/${shortId}/vacancies/${shortId}/responses`)}
            >
              <div className="response-top__back-button_arrow">
                <FontAwesomeIcon
                  icon="arrow-left"
                />
              </div>
              Назад
            </button>
          </Col>
          <Col md="4" lg="4" className="response-left">
            <div className="response-left__edit-icon">
              <FontAwesomeIcon
                className="item-icon"
                icon="pencil-alt"
                onClick={this.toggleIsEditable}
              />
            </div>
            {isEditable
              ? (
                <React.Fragment>
                  <ResponseForm
                    onSubmit={this.handleSubmit}
                    initialValues={response.fields}
                    response={response}
                  />
                  <div className="buttons-container">
                    <button
                      className="button"
                      type="button"
                      onClick={submitForm}
                    >
                      Сохранить
                    </button>
                    <button
                      className="button button_color_dark-blue"
                      type="button"
                      onClick={this.toggleIsEditable}
                    >
                      Отменить
                    </button>
                  </div>
                </React.Fragment>
              )
              : (
                <div className="applicant">
                  <ResponseData response={response} />
                </div>
              )
            }
            <ResponseQuestionsList questions={questions} response={response} />
            <div className="buttons-container">
              <ConfirmModal
                type="button"
                headerText="Удалить отклик?"
                buttonText="Удалить отклик"
                modalAction={this.handleDelete}
              />
            </div>
            <StatusMessage
              status="editResponseStatus"
              errorMessage="Ошибка при сохранении изменений"
            />
            <StatusMessage
              status="deleteResponseStatus"
              errorMessage="Ошибка при удалении отклика"
            />
          </Col>
          <Col md="6" lg="7" className="response-right">
            <ApplicantStatus
              response={response}
              vacancy={vacancy}
              hiringTemplate={hiringTemplate}
              changeStatus={this.changeStatus}
              vacancies={vacancies}
              moveResponse={moveResponse}
            />
            <ResponseHistory
              responseChanges={response_changes}
            />
            <CommentsContainer
              userId={userId}
              teamAdmins={teamAdmins}
              response={response}
              comments={comments}
              addComment={addComment}
              editComment={editComment}
              deleteComment={deleteComment}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ResponseReview.propTypes = {
  responseId: PropTypes.number.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadResponse: PropTypes.func.isRequired,
  editResponse: PropTypes.func.isRequired,
  moveResponse: PropTypes.func.isRequired,
  deleteResponse: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  markResponseAsViewed: PropTypes.func.isRequired,
  vacancy: PropTypes.object.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  hiringTemplate: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  teamAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    app: { activeTeam, isLoading, user: userId },
    entities: { teams },
    responses: { responses = [] },
    errors,
    questions,
  } = state;

  const team = teams[activeTeam] || {};
  const { vacancies = [] } = team;
  const responseId = +ownProps.match.params.responseId;

  const vacancyId = +ownProps.match.params.vacancyId;
  const vacancy = vacancies.find(vacancy => vacancy.id === vacancyId);

  const hiringTemplate = (vacancy && team.hiring_templates.find(
    template => template.id === vacancy.hiring_template,
  )) || {};
  const teamAdmins = [...team.admins, team.owner];

  return {
    vacancies,
    userId,
    teamAdmins,
    isLoading,
    responses,
    responseId,
    vacancy,
    hiringTemplate,
    questions,
    errors,
  };
};

const mapDispatchToProps = dispatch => ({
  editResponse: response => dispatch(editResponse(response)),
  moveResponse: (response, sourceVacancyId, targetVacancyId) => dispatch(
    moveResponse(response, sourceVacancyId, targetVacancyId),
  ),
  deleteResponse: (response, vacancyId) => dispatch(deleteResponse(response, vacancyId)),
  changeStatus: response => dispatch(changeStatus(response)),
  addComment: (response, comment) => dispatch(addComment(response, comment)),
  editComment: (response, comment) => dispatch(editComment(response, comment)),
  deleteComment: (response, comment) => dispatch(deleteComment(response, comment)),
  submitForm: () => dispatch(submit('response')),
  markResponseAsViewed: response => dispatch(markResponseAsViewed(response)),
  loadResponse: id => dispatch(loadResponse(id)),
  loadResponses: () => dispatch(loadResponses()),
});

export default withLoading(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResponseReview),
), 'center');
