import React from 'react';
import PropTypes from 'prop-types';
import appConfig from '../configs/appConfig';


class DemoWidget extends React.Component {
  componentDidMount() {
    const widgetScript = document.getElementById('hrs_job-widget');
    if (widgetScript) {
      widgetScript.remove();
    }
    this.initializeWidget();
  }

  componentDidUpdate(prevProps) {
    const { widgetId } = this.props;
    if (prevProps.widgetId !== widgetId) {
      const widgetScript = document.getElementById('hrs_job-widget');
      const iframe = document.getElementsByTagName('iframe')[0];
      if (iframe) {
        iframe.remove();
        widgetScript.remove();
        this.initializeWidget();
      }
    }
  }

  initializeWidget() {
    const { widgetId } = this.props;
    if (!document.getElementById('hrs_job-widget')) {
      const { SITE_URL } = appConfig;
      window.JW_ID = widgetId;
      const hrs = document.createElement('script');
      hrs.type = 'text/javascript';
      hrs.id = 'hrs_job-widget';
      hrs.async = true;
      hrs.src = `${SITE_URL}/static/js/embed.js`;
      hrs.charset = 'utf-8';

      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hrs);
    }
  }

  render() {
    return <div id="hr_services" className="demo-widget-container" />;
  }
}

DemoWidget.propTypes = {
  widgetId: PropTypes.string.isRequired,
};

export default DemoWidget;
