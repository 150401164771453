import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import CreateResponseForm from '../forms/CreateResponseForm';
import { createResponse } from '../actions/responses';
import Breadcrumbs from '../components/Breadcrumbs';


class CreateResponse extends React.Component {
    handleSubmit = (values) => {
      const { createResponse, location, user } = this.props;
      const isAllVacancyPage = location.pathname.includes('all-vacancies');

      const questionsArray = values.questions && values.questions
        .map((question) => {
          const key = Object.keys(question)[0];
          return { question: key.split('_').pop(), text: question[key] };
        });

      const formData = new FormData();

      if (!isAllVacancyPage) {
        formData.append('creator', user);
      }

      Object.keys(values).forEach((key) => {
        if (key.includes('userFile_')) {
          formData.append(key.split('userFile_').pop(), values[key][0]);
        } else if (key !== 'vacancy' && key !== 'questions') {
          formData.append(key, values[key]);
        }
      });

      if (questionsArray) {
        formData.append('question_answers', JSON.stringify(questionsArray));
      }

      const { vacancies } = this.props;
      const { id } = vacancies[0];
      const vacancyId = isAllVacancyPage ? id : values.vacancy && values.vacancy.value.id;
      formData.append('vacancy', vacancyId);

      createResponse(formData, isAllVacancyPage, vacancyId);
    }

    render() {
      const {
        vacancies,
        activeTeamShortId,
        fieldSets,
        fields,
        questions,
        location,
        default_field_set,
      } = this.props;

      const isAllVacancyPage = location.pathname.includes('all-vacancies');

      const breadcrumbs = [
        { title: 'Вакансии', link: `/teams/${activeTeamShortId}/vacancies` },
        { title: 'Отклики', link: `/teams/${activeTeamShortId}/vacancies/responses` },
        { title: 'Создать отклик', link: '' },
      ];

      const notDeletedQuestions = questions.filter(question => question.deleted === null);

      let vacanciesWithModFields = [...vacancies];

      if (!isAllVacancyPage) {
        vacanciesWithModFields = vacancies.map((vacancy) => {
          const currentFieldSet = fieldSets.find(field => field.id === vacancy.jwfield_set)
          || fieldSets.find(field => field.id === default_field_set);
          const modifiedFields = currentFieldSet.fields.map((currentField) => {
            const modifiedField = fields.find(field => field.id === currentField.field_id);
            return { ...currentField, ...modifiedField };
          });
          return { ...vacancy, fields: [...modifiedFields] };
        });
      }

      return (
        <React.Fragment>
          <Helmet>
            <title>Создание отклика | Jobs Widget</title>
          </Helmet>
          { !isAllVacancyPage && <Breadcrumbs breadcrumbs={breadcrumbs} /> }
          <CreateResponseForm
            onSubmit={this.handleSubmit}
            vacancies={vacanciesWithModFields}
            fields={fields}
            questions={notDeletedQuestions}
            isAllVacancyPage={isAllVacancyPage}
          />
        </React.Fragment>
      );
    }
}

CreateResponse.defaultProps = {
  vacancies: [],
  fieldSets: [],
  fields: [],
  questions: [],
  activeTeamShortId: '',
};


CreateResponse.propTypes = {
  fieldSets: PropTypes.arrayOf(PropTypes.object),
  vacancies: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.arrayOf(PropTypes.object),
  createResponse: PropTypes.func.isRequired,
  activeTeamShortId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
  user: PropTypes.number.isRequired,
  default_field_set: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: { activeTeam, activeTeamShortId },
    entities: { teams },
    fieldSets,
    fields,
    questions,
    app: { user },
  } = state;

  if (!activeTeam) {
    return { };
  }

  const team = teams[activeTeam];
  const { default_field_set } = team;
  return {
    user,
    fields,
    fieldSets,
    vacancies: team.vacancies && team.vacancies.filter(vacancy => !vacancy.deleted),
    activeTeam,
    activeTeamShortId,
    questions,
    default_field_set,
  };
};


const mapDispatchToProps = dispatch => ({
  createResponse: (response, isAllVacancyPage, vacancyId) => (
    dispatch(createResponse(response, isAllVacancyPage, vacancyId))
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateResponse));
