import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';
import ResponseItem from './ResponseItem';
import withPagination from '../containers/withPagination';
import withSelection from '../containers/withSelection';
import ConfirmModal from './ConfirmModal';
import withLoading from './withLoading';
import { RESPONSE_PAGE } from '../constants/pageNames';


class ResponsesList extends React.Component {
  constructor(props) {
    super(props);
    const { items: responses } = props;
    this.state = {
      filteredResponses: responses,
      activatedMenu: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { selectedItems, items: responses } = this.props;
    const { activatedMenu } = this.state;
    if (activatedMenu && (!selectedItems || selectedItems.length !== prevProps.selectedItems.length)) {
      this.handleActivatedMenu();
    }

    if (!isEqual(responses, prevProps.items)) {
      this.onResponsesUpdate(responses);
    }
  }

  handleActivatedMenu = () => {
    this.setState(state => ({ activatedMenu: !state.activatedMenu }));
  };

  onResponsesUpdate = (responses) => {
    this.setState({ filteredResponses: responses });
  }

  resetResponsesSelection = () => {
    const { selectedItems, resetSelection } = this.props;
    if (selectedItems && selectedItems.length) {
      resetSelection('selectedResponses');
    }
  };

  deleteMultipleResponses = () => {
    const {
      selectedItems,
      items,
      deleteResponses,
      resetSelection,
      filters,
      pageNumber,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      const vacanciesId = selectedItems.reduce((acc, responseId) => {
        const vacancyId = items.find(({ id }) => id === responseId).vacancy;
        return [...acc, vacancyId];
      }, []);
      deleteResponses(selectedItems, vacanciesId, filters, pageNumber);
      resetSelection('selectedResponses');
    }
  };

  markAsViewedMultipleResponses = () => {
    const {
      selectedItems,
      resetSelection,
      markResponseAsViewed,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      selectedItems.forEach((response) => {
        markResponseAsViewed({ id: response });
      });
      resetSelection('selectedResponses');
    }
  };

  selectAllItemsOnPage = () => {
    const {
      selectedItems,
      toggleSelection,
    } = this.props;
    const { filteredResponses } = this.state;

    const responsesIds = filteredResponses.map(response => response.id);
    const notSelectedItemsOnPage = responsesIds.filter(id => !selectedItems.includes(id));
    if (notSelectedItemsOnPage.length) {
      notSelectedItemsOnPage.forEach(id => toggleSelection(id));
    } else {
      responsesIds.forEach(id => toggleSelection(id));
    }
  };

  render() {
    const {
      vacancies,
      toggleSelection,
      selectedItems,
      resetSelection,
    } = this.props;

    const {
      filteredResponses,
    } = this.state;

    const { activatedMenu } = this.state;
    const responseItems = filteredResponses.length
      ? filteredResponses.map(response => (
        <ResponseItem
          key={response.id}
          response={response}
          vacancies={vacancies}
          toggleSelection={toggleSelection}
          isSelected={selectedItems.includes(response.id)}
        />
      ))
      : <div className="content-container__empty">Здесь будут отображаться отклики кандидатов на ваши вакансии</div>;

    const selectAllButton = (
      <button
        type="button"
        className="button-add-item span vacancies-icons-wrap-button"
        onClick={this.selectAllItemsOnPage}
      >
        <span className="button-text">Выбрать все</span>
      </button>
    );

    const multipleChoicePanel = (
      <React.Fragment>
        <ConfirmModal
          type="icon-text"
          buttonText="удалить"
          headerText={`Удалить выбранные отклики (${selectedItems.length})?`}
          modalAction={this.deleteMultipleResponses}
        />
        <button
          type="button"
          className="button-add-item span"
          onClick={this.markAsViewedMultipleResponses}
        >
          <FontAwesomeIcon
            className="item-icon"
            icon="check-square"
          />
          <span className="vacancy-item-description-elem">отметить как прочитанное</span>
        </button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="vacancies-page-sort">
          <div className="d-flex align-items-center vacancies-page-selected-actions">
            <div className="button-select-all">
              {selectAllButton}
            </div>
            {selectedItems.length !== 0 && (
              <React.Fragment>
                <span className="icons-wrap-button multiple-select">
                  {selectedItems.length}
                  {' '}
                  выбрано
                </span>
                <button
                  type="button"
                  className="button-add-item span button-multiple"
                  onClick={() => resetSelection('selectedResponses')}
                >
                  <FontAwesomeIcon
                    className="button-text"
                    icon="times"
                  />
                </button>
                <div className="mobile-button-multiple-menu">
                  <button
                    type="button"
                    className="button-add-item span"
                    onClick={this.handleActivatedMenu}
                  >
                    <span className="vacancy-item-description-elem">действия</span>
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="ellipsis-v"
                    />
                  </button>
                  <Collapse
                    isOpen={activatedMenu}
                    className="mobile-multiple-menu"
                  >
                    {selectAllButton}
                    {multipleChoicePanel}
                  </Collapse>
                </div>
              </React.Fragment>
            )}
          </div>
          {selectedItems.length !== 0 && (
            <div className="vacancies-page-multiple mb-1 ml-3">
              {multipleChoicePanel}
            </div>
          )}
        </div>
        <div className="content-container content-container_padding_sm">
          {responseItems}
        </div>
      </React.Fragment>
    );
  }
}

ResponsesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleSelection: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetSelection: PropTypes.func.isRequired,
  deleteResponses: PropTypes.func.isRequired,
  markResponseAsViewed: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
};


export default withLoading(withPagination(withSelection(ResponsesList, { name: 'selectedResponses' }), RESPONSE_PAGE));
export { ResponsesList as PureResponsesList };
