export default function getManagersFromResponse(responses) {
  const managersWhoChanged = [...new Set(
    responses
      .map(({ response_changes }) => response_changes.map(({ user }) => user))
      .flat(),
  )];

  const managersWhoCreated = responses.map(
    ({ creator }) => creator,
  ).filter(managerInfo => managerInfo);

  return [...managersWhoChanged, ...managersWhoCreated]
    .filter((user, index, arr) => {
      const indexOfSameManager = arr
        .map(user => user.id)
        .lastIndexOf(user.id);
      return indexOfSameManager === index;
    });
}
