import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  formValueSelector,
  isDirty,
  FieldArray,
  stopSubmit,
} from 'redux-form';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Prompt } from 'react-router-dom';

import renderField from './render/renderField';
import htmlTextAreaField from './render/renderHtmlTextArea';
import asyncSelectField from './render/renderAsyncSelect';
import { getAddresses, getCities } from '../actions/app';
import renderSelectField from './render/renderSelectField';
import validateVacancyForm from './validate/validateVacancyForm';
import renderQuestions from './render/renderQuestions';
import { TOOLTIP_TEXTS } from '../utils';
import ErrorMessage from '../components/ErrorMessage';
import {
  currencyOptions,
  defaultCountryOptions,
  experienceOptions,
  scheduleOptions,
  specializationOptions,
} from './constants/vacancyFormConstants';
import DownloadImage from '../components/DownloadImage';


class CreateVacancyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdvancedSettingsOpen: false,
    };
    this.fileRef = React.createRef();
  }

  toggleAdvancedSettings = () => {
    this.setState(state => ({ isAdvancedSettingsOpen: !state.isAdvancedSettingsOpen }));
  };

  render() {
    const { isAdvancedSettingsOpen } = this.state;
    const {
      handleSubmit,
      isSubmitWithPublish,
      submitting,
      team,
      countries,
      country,
      city,
      fieldSets,
      dirty,
      submitSucceeded,
      change,
      questions,
      questionsData,
      submitFailed,
      activeTeamShortId,
      isAddAddress,
      toggleAddAddress,
      onVacancyImageChange,
    } = this.props;

    let searchDelayTimer;

    const addressOptions = team.vacancies.map(vacancy => ({
      label: `${vacancy.country}, ${vacancy.city}, ${vacancy.address}`,
      value: {
        country: vacancy.country,
        city: vacancy.city,
        address: vacancy.address,
      },
    })).sort((a, b) => ((a.label > b.label) ? 1 : -1))
      .filter((adds, index, arr) => (index > 0 ? adds.label !== arr[index - 1].label : arr[index]));

    const hiringTemplateOptions = team
      && team.hiring_templates.map(template => ({
        label: template.name, value: template.id,
      }));

    const jwFieldSetOptions = fieldSets.map(fieldSet => ({
      label: fieldSet.name, value: fieldSet.id,
    }));

    const defaultFieldSet = jwFieldSetOptions.find(fieldSet => fieldSet.value === team.default_field_set);

    const loadCountryOptions = inputValue => countries
      .filter(countryOption => countryOption.name.toLowerCase().includes(inputValue.toLowerCase()))
      .filter((value, index) => index < 10)
      .map(countryOption => ({ label: countryOption.name, value: countryOption.name }));

    const getCountryOptions = (inputValue, callback) => {
      callback(loadCountryOptions(inputValue));
    };

    const getCityOptions = inputValue => new Promise((resolve) => {
      clearTimeout(searchDelayTimer);
      searchDelayTimer = setTimeout(() => {
        resolve(getCities(country, inputValue)
          .then(({ data }) => data
            .map((city) => {
              const area = city.area ? `, ${city.area}` : '';
              const region = city.region ? `, ${city.region}` : '';
              return {
                label: city.name + area + region,
                value: city.name,
              };
            })));
      }, 500);
    });

    const getAddressOptions = (inputValue) => {
      if (country !== 'Россия') {
        return new Promise(resolve => resolve([{ label: inputValue, value: inputValue }]));
      }
      return new Promise((resolve) => {
        clearTimeout(searchDelayTimer);
        searchDelayTimer = setTimeout(() => {
          resolve(getAddresses(country, city, inputValue)
            .then(({ data }) => data
              .map(address => ({
                label: address,
                value: address,
              }))));
        }, 500);
      });
    };

    return (
      <form onSubmit={handleSubmit} className="vacancy-form">
        <Prompt
          when={dirty && !submitSucceeded}
          message="Изменения вакансии не сохранены. Вы уверены, что хотите покинуть страницу?"
        />
        <DownloadImage onImageChange={onVacancyImageChange} />
        <div className="vacancy-form__field">
          <Field
            name="title"
            type="text"
            component={renderField}
            label="Название вакансии"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="specialization"
            type="text"
            component={renderSelectField}
            options={specializationOptions}
            label="Отрасль"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="salary_from"
            type="number"
            component={renderField}
            label="Зарплата от"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="salary_to"
            type="number"
            component={renderField}
            label="Зарплата до"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="currency"
            component={renderSelectField}
            label="Валюта"
            options={currencyOptions}
            defaultValue={currencyOptions[0]}
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="schedule"
            component={renderSelectField}
            label="График работы"
            options={scheduleOptions}
            defaultValue={scheduleOptions[0]}
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="experience"
            component={renderSelectField}
            label="Опыт работы"
            options={experienceOptions}
            defaultValue={experienceOptions[0]}
          />
        </div>
        {addressOptions.length > 0 && (
          <React.Fragment>
            {!isAddAddress && (
            <div className="vacancy-form__field">
              <Field
                name="addressSelect"
                componentName="addressSelect"
                component={renderSelectField}
                label="Адрес"
                options={addressOptions}
                placeholder="Адрес не указан"
              />
            </div>
            )}
            <button
              type="button"
              className="button-add-item vacancy-form__button-add-item"
              onClick={toggleAddAddress}
            >
              <span>{isAddAddress ? '-' : '+'}</span>
              Новый адрес
            </button>
          </React.Fragment>
        )}
        {(isAddAddress || addressOptions.length === 0) && (
          <React.Fragment>
            <div className="vacancy-form__field">
              <Field
                name="country"
                componentName="country"
                component={asyncSelectField}
                label="Страна"
                placeholder="Начните вводить название страны..."
                noOptionsMessage="Страна не найдена"
                defaultOptions={defaultCountryOptions}
                loadOptions={getCountryOptions}
              />
            </div>
            <div className="vacancy-form__field">
              <Field
                name="city"
                componentName="city"
                component={asyncSelectField}
                label="Город"
                placeholder={country ? 'Начните вводить...' : 'Сначала выберите страну...'}
                noOptionsMessage="Город не найден"
                loadOptions={getCityOptions}
                isDisabled={!country}
              />
            </div>
            <div className="vacancy-form__field">
              <Field
                name="address"
                componentName="address"
                component={asyncSelectField}
                label="Адрес"
                placeholder={city ? 'Начните вводить...' : 'Сначала выберите город...'}
                noOptionsMessage="Адрес не найден"
                loadOptions={getAddressOptions}
                isDisabled={!city}
              />
            </div>
          </React.Fragment>
        )}
        <div className="vacancy-form__field">
          <Field
            name="description"
            componentName="description"
            component={htmlTextAreaField}
            label="Задачи"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="requirements"
            componentName="requirements"
            component={htmlTextAreaField}
            label="Требования"
          />
        </div>
        <div className="vacancy-form__field">
          <Field
            name="conditions"
            componentName="conditions"
            component={htmlTextAreaField}
            label="Условия"
          />
        </div>
        <button
          type="button"
          className="button-add-item vacancy-form__button-add-item"
          onClick={this.toggleAdvancedSettings}
        >
          <span>{isAdvancedSettingsOpen ? '-' : '+'}</span>
          Расширенные настройки
        </button>
        {isAdvancedSettingsOpen && (
          <React.Fragment>
            <div className="vacancy-form__field">
              <Field
                name="hiring_template"
                component={renderSelectField}
                label="Шаблон найма"
                tooltipText={TOOLTIP_TEXTS.hiringTemplate}
                options={hiringTemplateOptions}
                defaultValue={hiringTemplateOptions[0]}
              />
            </div>
            <div className="vacancy-form__field">
              <Field
                name="jwfield_set"
                component={renderSelectField}
                label="Набор полей"
                tooltipText={TOOLTIP_TEXTS.jwfieldSet}
                options={jwFieldSetOptions}
                defaultValue={defaultFieldSet}
              />
            </div>
            <div className="vacancy-form__field">
              <Field
                name="sort_order"
                type="number"
                component={renderField}
                label="Порядок сортировки"
                tooltipText={TOOLTIP_TEXTS.sortOrder}
              />
            </div>
          </React.Fragment>
        )}
        <FieldArray
          name="questions"
          component={renderQuestions}
          change={change}
          questions={questions}
          questionsData={questionsData}
        />
        <div className="buttons-container">
          <button
            className="button"
            type="submit"
            disabled={submitting}
            onClick={() => isSubmitWithPublish(true)}
          >
            Создать и опубликовать
          </button>
          <button
            className="button"
            type="submit"
            disabled={submitting}
            onClick={() => isSubmitWithPublish(false)}
          >
            Создать
          </button>
          <LinkContainer
            to={`/teams/${activeTeamShortId}/vacancies`}
          >
            <button type="button" className="button button_color_dark-blue">Отмена</button>
          </LinkContainer>
        </div>
        {submitFailed && <div className="form-error">Не все поля заполнены корректно</div>}
        <ErrorMessage errorName="createVacancyError" />
      </form>
    );
  }
}

CreateVacancyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitWithPublish: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  fieldSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitFailed: PropTypes.bool.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  isAddAddress: PropTypes.bool.isRequired,
  toggleAddAddress: PropTypes.func.isRequired,
  onVacancyImageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { app: { activeTeamShortId } } = state;
  const selector = formValueSelector('createVacancy');
  const dirty = isDirty('createVacancy')(state);
  const country = selector(state, 'country');
  const countryValue = country ? country.value : '';
  const city = selector(state, 'city');
  const cityValue = city ? city.value : '';
  const questionsData = selector(state, 'questions') || [];
  return {
    country: countryValue,
    city: cityValue,
    dirty,
    questionsData,
    activeTeamShortId,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'createVacancy',
    validate: validateVacancyForm,
    onChange: (values, dispatch, props) => {
      if (props.submitFailed) {
        dispatch(stopSubmit('createVacancy'));
      }
    },
  }),
)(CreateVacancyForm);
