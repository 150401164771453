import axios from '../api';
import {
  APPLY_COUPON_FAIL,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  GET_PAYMENT_INFO_FAIL,
  GET_PAYMENT_INFO_REQUEST,
  GET_PAYMENT_INFO_SUCCESS,
  UPDATE_PAYMENT_INFO_FAIL,
  UPDATE_PAYMENT_INFO_REQUEST,
  UPDATE_PAYMENT_INFO_SUCCESS,
} from '../constants/paymentConst';
import history from '../routes/history';


export const getPaymentInfo = () => ({
  name: 'paymentInfo',
  types: [GET_PAYMENT_INFO_REQUEST, GET_PAYMENT_INFO_SUCCESS, GET_PAYMENT_INFO_FAIL],
  callApi: () => axios.get('/payment/'),
});

export const updatePaymentInfo = paymentInfo => ({
  types: [UPDATE_PAYMENT_INFO_REQUEST, UPDATE_PAYMENT_INFO_SUCCESS, UPDATE_PAYMENT_INFO_FAIL],
  callApi: () => axios.patch('/payment/', { ...paymentInfo }),
});

export const applyCoupon = coupon_code => ({
  types: [APPLY_COUPON_REQUEST, APPLY_COUPON_SUCCESS, APPLY_COUPON_FAIL],
  callApi: () => axios.post('/payment/coupon/', {
    coupon_code,
  }),
  afterSuccessCall: () => history.push('/payment/success'),
});
