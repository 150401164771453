import axios from '../api';
import {
  ADD_COMMENT_FAIL,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_RESPONSE_FAIL,
  DELETE_RESPONSE_REQUEST,
  DELETE_RESPONSE_SUCCESS,
  EDIT_COMMENT_FAIL,
  EDIT_COMMENT_REQUEST,
  EDIT_COMMENT_SUCCESS,
  EDIT_RESPONSE_FAIL,
  EDIT_RESPONSE_REQUEST,
  EDIT_RESPONSE_SUCCESS,
  GET_RESPONSES_FAIL,
  GET_RESPONSES_REQUEST,
  GET_RESPONSES_SUCCESS,
  GET_RESPONSE_FAIL,
  GET_RESPONSE_REQUEST,
  GET_RESPONSE_SUCCESS,
  MARK_RESPONSE_AS_VIEWED_FAIL,
  MARK_RESPONSE_AS_VIEWED_REQUEST,
  MARK_RESPONSE_AS_VIEWED_SUCCESS,
  CREATE_RESPONSE_FAIL,
  CREATE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_REQUEST,
  DELETE_RESPONSES_REQUEST,
  DELETE_RESPONSES_SUCCESS,
  DELETE_RESPONSES_FAIL,
  MOVE_RESPONSE_REQUEST,
  MOVE_RESPONSE_SUCCESS,
  MOVE_RESPONSE_FAIL,
} from '../constants/responsesConst';
import store from '../store';
import history from '../routes/history';


export const loadResponses = (filters, page) => ({
  types: [GET_RESPONSES_REQUEST, GET_RESPONSES_SUCCESS, GET_RESPONSES_FAIL],
  callApi: () => axios.get('/responses/', { params: { filters, page } }),
  withLoading: true,
});

export const loadResponsesForReport = filters => ({
  types: [GET_RESPONSES_REQUEST, GET_RESPONSES_SUCCESS, GET_RESPONSES_FAIL],
  callApi: () => axios.get('/responses/report/', { params: { filters } }),
  withLoading: true,
});

export const loadResponse = id => ({
  types: [GET_RESPONSE_REQUEST, GET_RESPONSE_SUCCESS, GET_RESPONSE_FAIL],
  callApi: () => axios.get(`/responses/${id}/`),
  withLoading: true,
});

export const addComment = (response, comment) => ({
  types: [ADD_COMMENT_REQUEST, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAIL],
  callApi: () => axios.post(`/responses/${response.id}/comments/`, { message: comment.message }),
  payload: { response },
});

export const editComment = (response, comment) => ({
  name: 'comment',
  types: [EDIT_COMMENT_REQUEST, EDIT_COMMENT_SUCCESS, EDIT_COMMENT_FAIL],
  callApi: () => axios.patch(`/responses/${response.id}/comments/${comment.id}/`, { message: comment.message }),
  payload: { response },
});

export const deleteComment = (response, comment) => ({
  types: [DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAIL],
  callApi: () => axios.delete(`/responses/${response.id}/comments/${comment.id}/`),
  payload: { response, comment },
});

export const createResponse = (response, isAllVacanciesPage, vacancyId) => ({
  name: 'response',
  types: [CREATE_RESPONSE_REQUEST, CREATE_RESPONSE_SUCCESS, CREATE_RESPONSE_FAIL],
  callApi: () => axios.post(`${isAllVacanciesPage ? 'widget' : ''}/responses/`, response),
  payload: { vacancyId },
  afterSuccessCall: () => {
    const { app: { activeTeamShortId } } = store.getState();
    if (isAllVacanciesPage) {
      history.push('/all-vacancies');
    } else {
      history.push(`/teams/${activeTeamShortId}/vacancies/responses`);
    }
  },
});

export const editResponse = response => ({
  types: [EDIT_RESPONSE_REQUEST, EDIT_RESPONSE_SUCCESS, EDIT_RESPONSE_FAIL],
  callApi: () => axios.patch(`/responses/${response.id}/`, response),
  afterSuccessCall: () => {
    const { app: { activeTeamShortId } } = store.getState();
    history.push(`/teams/${activeTeamShortId}/vacancies/responses`);
  },
});

export const moveResponse = (responseId, sourceVacancyId, targetVacancyId) => ({
  types: [MOVE_RESPONSE_REQUEST, MOVE_RESPONSE_SUCCESS, MOVE_RESPONSE_FAIL],
  callApi: () => axios.patch(`/responses/${responseId}/`, { vacancy: targetVacancyId }),
  afterSuccessCall: () => {
    const { app: { activeTeamShortId } } = store.getState();
    history.push(`/teams/${activeTeamShortId}/vacancies/${targetVacancyId}/responses/${responseId}`);
  },
  payload: { sourceVacancyId },
});

export const changeStatus = response => ({
  types: [EDIT_RESPONSE_REQUEST, EDIT_RESPONSE_SUCCESS, EDIT_RESPONSE_FAIL],
  callApi: () => axios.patch(`/responses/${response.id}/`, response),
});

export const markResponseAsViewed = response => ({
  types: [MARK_RESPONSE_AS_VIEWED_REQUEST, MARK_RESPONSE_AS_VIEWED_SUCCESS, MARK_RESPONSE_AS_VIEWED_FAIL],
  callApi: () => axios.post(`/responses/${response.id}/mark_viewed/`),
  payload: { responseId: response.id },
});

export const deleteResponse = (id, vacancyId, filters, pageNumber, redirect = true) => ({
  types: [DELETE_RESPONSE_REQUEST, DELETE_RESPONSE_SUCCESS, DELETE_RESPONSE_FAIL],
  callApi: () => axios.delete(`/responses/${id}/`),
  payload: { id, vacancyId },
  afterSuccessCall: (data, dispatch) => {
    dispatch(loadResponses(filters, pageNumber));
    if (redirect) {
      const { app: { activeTeamShortId } } = store.getState();
      history.push(`/teams/${activeTeamShortId}/vacancies/responses`);
    }
  },
});

export const deleteResponses = (ids, vacancyId, filters, pageNumber, redirect = true) => ({
  types: [DELETE_RESPONSES_REQUEST, DELETE_RESPONSES_SUCCESS, DELETE_RESPONSES_FAIL],
  callApi: () => {
    const params = new URLSearchParams();
    ids.reduce((acc, id) => {
      params.append('ids', id);
      return acc;
    }, new URLSearchParams());
    const request = {
      params,
    };
    return axios.delete('/responses/', request);
  },
  payload: { ids, vacancyId },
  afterSuccessCall: (data, dispatch, getState) => {
    const {
      app: {
        activeTeamShortId,
        settings: { page_sizes: { responses } },
      },
      responses: { totalAmount },
    } = getState();
    const maxPage = Math.ceil(totalAmount / responses);
    if (pageNumber !== maxPage) {
      dispatch(loadResponses(filters, pageNumber));
    }
    if (redirect) {
      history.push(`/teams/${activeTeamShortId}/vacancies/responses`);
    }
  },
});
