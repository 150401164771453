export const defaultCountryOptions = [
  { label: 'Россия', value: 'Россия' },
  { label: 'Украина', value: 'Украина' },
  { label: 'Беларусь', value: 'Беларусь' },
  { label: 'Казахстан', value: 'Казахстан' },
];

export const currencyOptions = [
  { label: 'Рубли', value: 'rubles', sign: '₽' },
  { label: 'Доллары', value: 'dollars', sign: '$' },
  { label: 'Евро', value: 'euro', sign: '€' },
];

export const specializationOptions = [
  { label: 'Административная работа, секретариат, АХО', value: 'deskwork' },
  { label: 'IT, Интернет, связь, телеком', value: 'it' },
  { label: 'Банки, инвестиции, лизинг', value: 'banking' },
  { label: 'Безопасность, службы охраны', value: 'security' },
  { label: 'Бухгалтерия, финансы, аудит', value: 'bookkeeping' },
  { label: 'Государственная служба', value: 'governmentServices' },
  { label: 'Дизайн ', value: 'design' },
  { label: 'Домашний персонал', value: 'householdWorker' },
  { label: 'Закупки, снабжение', value: 'supply' },
  { label: 'Искусство, культура, развлечения', value: 'art' },
  { label: 'Кадры, управление персоналом', value: 'hr' },
  { label: 'Консалтинг, стратегическое развитие', value: 'consulting' },
  { label: 'Маркетинг, реклама, PR', value: 'pr' },
  { label: 'Медицина, фармацевтика, ветеринария', value: 'medicine' },
  { label: 'Наука, образование, повышение квалификации', value: 'education' },
  { label: 'Некоммерческие организации, волонтерство', value: 'volunteer' },
  { label: 'Продажи', value: 'selling' },
  { label: 'Промышленность, производство', value: 'manufacture' },
  { label: 'Рабочий персонал', value: 'workforce' },
  { label: 'Сельское хозяйство', value: 'agriculture' },
  { label: 'СМИ, издательства', value: 'massMedia' },
  { label: 'Спорт, фитнес, салоны красоты, SPA', value: 'lifestyle' },
  { label: 'Страхование Строительство, проектирование, недвижимость', value: 'insurance' },
  { label: 'Сырье', value: 'basicMaterial' },
  { label: 'Топ-персонал', value: 'administration' },
  { label: 'Транспорт, логистика, ВЭД', value: 'logistics' },
  { label: 'Туризм, гостиницы, общественное питание', value: 'tourism' },
  { label: 'Услуги, ремонт, сервисное обслуживание', value: 'service' },
  { label: 'Юриспруденция', value: 'jurisprudence' },
  { label: 'Прочее', value: 'others' },
];

export const scheduleOptions = [
  { label: 'Полный день', value: 'fullDay' },
  { label: 'Гибкий график', value: 'flexible' },
  { label: 'Сменный график', value: 'shift' },
  { label: 'Удаленная работа', value: 'remote' },
  { label: 'Вахтовый метод', value: 'flyInFlyOut' },
];

export const experienceOptions = [
  { label: 'Не требуется', value: 'noExperience' },
  { label: 'От 1 до 3 лет', value: 'between1And3' },
  { label: 'От 3 до 6 лет', value: 'between3And6' },
  { label: 'Более 6 лет', value: 'moreThan6' },
];
