import React from 'react';
import PropTypes from 'prop-types';


const ChangeStatusButtons = (props) => {
  const getChangeStatusButtons = () => {
    const {
      status,
      hiringTemplate,
      changeStatus,
    } = props;
    return hiringTemplate.actions
      .filter(action => action.from_state === status)
      .map(action => (
        <button
          type="button"
          className="button-tag-item response-tags__item"
          key={action.button_name}
          onClick={() => changeStatus(action.to_state)}
        >
          {action.button_name}
        </button>
      ));
  };
  return getChangeStatusButtons();
};

ChangeStatusButtons.propTypes = {
  status: PropTypes.string.isRequired,
  hiringTemplate: PropTypes.object.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

export default ChangeStatusButtons;
