import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';


class HiringProcessItem extends React.Component {
  handleDelete = () => {
    const { template, deleteHiringTemplate } = this.props;
    deleteHiringTemplate(template);
  };

  render() {
    const {
      template,
      defaultTemplate,
      match: { params: { shortId } },
    } = this.props;
    return (
      <div
        key={template.id}
        className={`content-container__item content-container__flex-between-center scroll-position-${template.id}`}
      >
        <Link className="field-sets-item-link" to={`/teams/${shortId}/templates/${template.id}`}>
          {template.name}
        </Link>
        {' '}
        {template.id !== defaultTemplate && (
          <ConfirmModal
            type="icon"
            headerText="Удалить сценарий найма?"
            modalAction={this.handleDelete}
            icon="trash-alt"
          />
        )}
      </div>
    );
  }
}

HiringProcessItem.propTypes = {
  template: PropTypes.object.isRequired,
  defaultTemplate: PropTypes.number.isRequired,
  deleteHiringTemplate: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(HiringProcessItem);
