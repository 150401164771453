import React, { Component } from 'react';
import PropTypes from 'prop-types';


const SIZE_LIMIT_IN_MEGABYTES = 10;
const BYTES_IN_MEGABYTE = 1048576;
const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];


class DownloadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      errorText: '',
    };
    this.fileRef = React.createRef();
  }

  resetImage = () => {
    const { onImageChange } = this.props;
    this.setState({ image: '' });
    onImageChange(null);
  }

  onFileChange = (event) => {
    const { onImageChange } = this.props;
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        const errorText = `${file.name} имеет недопустимый формат. Используйте ${ALLOWED_EXTENSIONS.join(', ')}.`;
        this.setState({ errorText });
      } else if (file.size > SIZE_LIMIT_IN_MEGABYTES * BYTES_IN_MEGABYTE) {
        const errorText = `${file.name} превышает максимальный разрешенный размер в ${SIZE_LIMIT_IN_MEGABYTES} МБ.`;
        this.setState({ errorText });
      } else {
        this.setAvatar(file);
        onImageChange(file);
        this.setState({ errorText: '' });
      }
    }
  };

  setAvatar = (file) => {
    this.getBase64FromBlob(file)
      .then((base64) => {
        this.setState({ image: base64 });
      });
  };

  getBase64FromBlob = blob => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

  onFileClick = () => {
    const fileInput = this.fileRef.current;
    fileInput.click();
  };

  render() {
    const {
      image,
      errorText,
    } = this.state;

    const {
      currentImage,
      changeImageText,
      addImageText,
      imageType,
      deleteImageText,
    } = this.props;

    return (
      <div className="vacancy-form__field">
        <div className="vacancy-form-logo-wrapper">
          {(image || currentImage) && (
            <div className="vacancy-form-logo mb-2">
              <img src={image || currentImage} alt="" />
            </div>
          )}
          <input
            name="logo"
            className="profile-avatar-file-input"
            type="file"
            accept=".jpg, .jpeg, .png"
            ref={this.fileRef}
            onChange={this.onFileChange}
          />
          <span
            className="profile-avatar-text light-green"
            onClick={this.onFileClick}
          >
            {`${(image || currentImage) ? changeImageText : addImageText} ${imageType}`}
          </span>
          {(image || currentImage) && (
            <span className="ml-4 profile-avatar-text light-green" onClick={this.resetImage}>
              {deleteImageText}
            </span>
          )}
          {errorText && <div className="form-error mt-1">{errorText}</div>}
        </div>
      </div>
    );
  }
}

DownloadImage.defaultProps = {
  currentImage: null,
  imageType: 'изображение',
  addImageText: 'Добавить',
  deleteImageText: 'Удалить',
  changeImageText: 'Изменить',
  onImageChange: () => {},
};

DownloadImage.propTypes = {
  currentImage: PropTypes.object,
  imageType: PropTypes.string,
  addImageText: PropTypes.string,
  deleteImageText: PropTypes.string,
  changeImageText: PropTypes.string,
  onImageChange: PropTypes.func,
};

export default DownloadImage;
