const getAddressOptionsFromTeam = team => team.vacancies.map(vacancy => ({
  label: `${vacancy.country
    ? `${vacancy.country}`
    : ''}${vacancy.city
    ? `, ${vacancy.city}`
    : ''}${vacancy.address
    ? `, ${vacancy.address}`
    : ''}`,
  value: {
    country: vacancy.country,
    city: vacancy.city,
    address: vacancy.address,
  },
})).sort((a, b) => ((a.label > b.label) ? 1 : -1))
  .filter(info => info.label);

export default getAddressOptionsFromTeam;
