import {
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_HIRING_TEMPLATE_FAIL,
  CREATE_HIRING_TEMPLATE_REQUEST,
  CREATE_HIRING_TEMPLATE_SUCCESS,
  CREATE_NEW_TEAM_FAIL,
  CREATE_NEW_TEAM_REQUEST,
  CREATE_NEW_TEAM_SUCCESS,
  CREATE_VACANCY_FAIL,
  CREATE_VACANCY_REQUEST,
  CREATE_VACANCY_SUCCESS,
  EDIT_HH_ID_FAIL,
  EDIT_HH_ID_REQUEST,
  EDIT_HH_ID_SUCCESS,
  EDIT_HIRING_TEMPLATE_FAIL,
  EDIT_HIRING_TEMPLATE_REQUEST,
  EDIT_HIRING_TEMPLATE_SUCCESS,
  EDIT_STYLE_FAIL,
  EDIT_STYLE_REQUEST,
  EDIT_STYLE_SUCCESS,
  EDIT_TEAM_NAME_FAIL,
  EDIT_TEAM_NAME_REQUEST,
  EDIT_TEAM_NAME_SUCCESS,
  EDIT_VACANCY_FAIL,
  EDIT_VACANCY_REQUEST,
  EDIT_VACANCY_SUCCESS,
  EDIT_VACANCIES_REQUEST,
  EDIT_VACANCIES_SUCCESS,
  EDIT_VACANCIES_FAIL,
  DELETE_VACANCIES_REQUEST,
  DELETE_VACANCIES_SUCCESS,
  DELETE_VACANCIES_FAIL,
  IMPORT_HH_VACANCIES_FAIL,
  IMPORT_HH_VACANCIES_REQUEST,
  IMPORT_HH_VACANCIES_SUCCESS,
  SEND_INVITE_FAIL,
  SEND_INVITE_REQUEST,
  SEND_INVITE_SUCCESS,
  EDIT_TEAM_URL_REQUEST,
  EDIT_TEAM_URL_SUCCESS,
  EDIT_TEAM_URL_FAIL,
} from '../constants/teamConst';

import {
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  RESET_API_STATUS,
} from '../constants/appConst';

import {
  CREATE_FIELD_FAIL,
  CREATE_FIELD_SUCCESS,
  CREATE_FIELD_REQUEST,
  EDIT_FIELD_REQUEST,
  EDIT_FIELD_SUCCESS,
  EDIT_FIELD_FAIL,
  DELETE_FIELD_REQUEST,
  DELETE_FIELD_SUCCESS,
  DELETE_FIELD_FAIL,
} from '../constants/fieldConst';

import {
  DELETE_RESPONSE_FAIL,
  DELETE_RESPONSE_REQUEST,
  DELETE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_REQUEST,
  CREATE_RESPONSE_FAIL,
  EDIT_RESPONSE_FAIL,
  EDIT_RESPONSE_REQUEST,
  EDIT_RESPONSE_SUCCESS,
} from '../constants/responsesConst';

import {
  APPLY_COUPON_FAIL,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
} from '../constants/paymentConst';

import {
  CREATE_QUESTION_FAIL,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  EDIT_QUESTION_FAIL,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
} from '../constants/questionsConst';

import {
  DELETE_FIELD_SET_FAIL,
  DELETE_FIELD_SET_REQUEST,
  DELETE_FIELD_SET_SUCCESS,
  EDIT_FIELD_SET_FAIL,
  EDIT_FIELD_SET_REQUEST,
  EDIT_FIELD_SET_SUCCESS,
  CREATE_FIELD_SET_FAIL,
  CREATE_FIELD_SET_REQUEST,
  CREATE_FIELD_SET_SUCCESS,
} from '../constants/fieldSetConst';

import {
  CREATE_HIRING_STATE_REQUEST,
  CREATE_HIRING_STATE_SUCCESS,
  CREATE_HIRING_STATE_FAIL,
  EDIT_HIRING_STATE_REQUEST,
  EDIT_HIRING_STATE_SUCCESS,
  EDIT_HIRING_STATE_FAIL,
  DELETE_HIRING_STATE_SUCCESS,
  DELETE_HIRING_STATE_REQUEST,
  DELETE_HIRING_STATE_FAIL,
} from '../constants/hiringTemplateConst';

import { createApiReducer } from '../utils/reducerUtils';


const resetApiStatus = (state, action) => {
  const newState = { ...state };
  delete newState[action.name];
  return newState;
};

const initialState = {};

const apiReducer = createApiReducer(
  initialState,
  [
    {
      types: [DELETE_VACANCIES_REQUEST, DELETE_VACANCIES_SUCCESS, DELETE_VACANCIES_FAIL],
      name: 'deleteVacanciesStatus',
    },
    {
      types: [EDIT_VACANCIES_REQUEST, EDIT_VACANCIES_SUCCESS, EDIT_VACANCIES_FAIL],
      name: 'editVacanciesStatus',
    },
    {
      types: [EDIT_VACANCY_REQUEST, EDIT_VACANCY_SUCCESS, EDIT_VACANCY_FAIL],
      name: 'editVacancyStatus',
    },
    {
      types: [SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_FAIL],
      name: 'inviteStatus',
    },
    {
      types: [CHANGE_EMAIL_REQUEST, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAIL],
      name: 'changeEmailStatus',
    },
    {
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
      name: 'changePasswordStatus',
    },
    {
      types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAIL],
      name: 'editProfileStatus',
    },
    {
      types: [CREATE_VACANCY_REQUEST, CREATE_VACANCY_SUCCESS, CREATE_VACANCY_FAIL],
      name: 'createVacancyStatus',
    },
    {
      types: [CREATE_RESPONSE_REQUEST, CREATE_RESPONSE_SUCCESS, CREATE_RESPONSE_FAIL],
      name: 'createResponseStatus',
    },
    {
      types: [EDIT_RESPONSE_REQUEST, EDIT_RESPONSE_SUCCESS, EDIT_RESPONSE_FAIL],
      name: 'editResponseStatus',
    },
    {
      types: [DELETE_RESPONSE_REQUEST, DELETE_RESPONSE_SUCCESS, DELETE_RESPONSE_FAIL],
      name: 'deleteResponseStatus',
    },
    {
      types: [CREATE_HIRING_TEMPLATE_REQUEST, CREATE_HIRING_TEMPLATE_SUCCESS, CREATE_HIRING_TEMPLATE_FAIL],
      name: 'createHiringTemplate',
    },
    {
      types: [EDIT_HIRING_TEMPLATE_REQUEST, EDIT_HIRING_TEMPLATE_SUCCESS, EDIT_HIRING_TEMPLATE_FAIL],
      name: 'editHiringTemplate',
    },
    {
      types: [EDIT_STYLE_REQUEST, EDIT_STYLE_SUCCESS, EDIT_STYLE_FAIL],
      name: 'editStyleStatus',
    },
    {
      types: [CREATE_NEW_TEAM_REQUEST, CREATE_NEW_TEAM_SUCCESS, CREATE_NEW_TEAM_FAIL],
      name: 'createNewTeamStatus',
    },
    {
      types: [APPLY_COUPON_REQUEST, APPLY_COUPON_SUCCESS, APPLY_COUPON_FAIL],
      name: 'applyCouponStatus',
    },
    {
      types: [EDIT_TEAM_NAME_REQUEST, EDIT_TEAM_NAME_SUCCESS, EDIT_TEAM_NAME_FAIL],
      name: 'editTeamNameStatus',
    },
    {
      types: [EDIT_TEAM_URL_REQUEST, EDIT_TEAM_URL_SUCCESS, EDIT_TEAM_URL_FAIL],
      name: 'editTeamUrlStatus',
    },
    {
      types: [CREATE_FIELD_SET_REQUEST, CREATE_FIELD_SET_SUCCESS, CREATE_FIELD_SET_FAIL],
      name: 'createFieldSetStatus',
    },
    {
      types: [EDIT_FIELD_SET_REQUEST, EDIT_FIELD_SET_SUCCESS, EDIT_FIELD_SET_FAIL],
      name: 'editFieldSetStatus',
    },
    {
      types: [DELETE_FIELD_SET_REQUEST, DELETE_FIELD_SET_SUCCESS, DELETE_FIELD_SET_FAIL],
      name: 'deleteFieldSetStatus',
    },
    {
      types: [CREATE_QUESTION_REQUEST, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_FAIL],
      name: 'createQuestionStatus',
    },
    {
      types: [EDIT_QUESTION_REQUEST, EDIT_QUESTION_SUCCESS, EDIT_QUESTION_FAIL],
      name: 'editQuestionStatus',
    },
    {
      types: [EDIT_HH_ID_REQUEST, EDIT_HH_ID_SUCCESS, EDIT_HH_ID_FAIL],
      name: 'editHhIdStatus',
    },
    {
      types: [IMPORT_HH_VACANCIES_REQUEST, IMPORT_HH_VACANCIES_SUCCESS, IMPORT_HH_VACANCIES_FAIL],
      name: 'importHhVacanciesStatus',
    },
    {
      types: [CREATE_FIELD_REQUEST, CREATE_FIELD_SUCCESS, CREATE_FIELD_FAIL],
      name: 'createFieldStatus',
    },
    {
      types: [EDIT_FIELD_REQUEST, EDIT_FIELD_SUCCESS, EDIT_FIELD_FAIL],
      name: 'editFieldStatus',
    },
    {
      types: [DELETE_FIELD_REQUEST, DELETE_FIELD_SUCCESS, DELETE_FIELD_FAIL],
      name: 'deleteFieldStatus',
    },
    {
      types: [CREATE_HIRING_STATE_REQUEST, CREATE_HIRING_STATE_SUCCESS, CREATE_HIRING_STATE_FAIL],
      name: 'createHiringState',
    },
    {
      types: [EDIT_HIRING_STATE_REQUEST, EDIT_HIRING_STATE_SUCCESS, EDIT_HIRING_STATE_FAIL],
      name: 'editHiringState',
    },
    {
      types: [DELETE_HIRING_STATE_REQUEST, DELETE_HIRING_STATE_SUCCESS, DELETE_HIRING_STATE_FAIL],
      name: 'deleteHiringState',
    },
  ],
  {
    [RESET_API_STATUS]: resetApiStatus,
  },
);

export default apiReducer;
