import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from 'react-onclickoutside';

import { logoutUser } from '../actions/app';
import ActiveTeamToggle from './ActiveTeamToggle';


const LARGE_SCREEN_WIDTH = 1600;

class NavigationBar extends React.Component {
  state = {
    isOpen: false,
    isSettingsOpen: false,
  };

  openMenu = () => {
    if (window.innerWidth < LARGE_SCREEN_WIDTH) {
      this.setState({ isOpen: true });
    }
  };

  closeMenu = () => {
    if (window.innerWidth < LARGE_SCREEN_WIDTH) {
      this.setState({
        isOpen: false,
        isSettingsOpen: false,
      });
    }
  };

  toggleMenu = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  };

  settingsToggle = () => {
    const { isOpen } = this.state;
    this.toggleSettingsCollapse();
    if (!isOpen) {
      this.toggleMenu();
    }
  };

  toggleSettingsCollapse = () => {
    this.setState(state => ({
      isSettingsOpen: !state.isSettingsOpen,
    }));
  };

  // This function is used to close the navigation bar when clicking outside
  handleClickOutside = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.closeMenu();
    }
  };

  isVacanciesActive = (match, location) => {
    const pathSegments = location.pathname.split('/');
    const isVacancies = pathSegments[3] === 'vacancies' && pathSegments[4] !== 'responses' && !pathSegments[5];
    const isArchive = pathSegments[3] === 'archive' && pathSegments[4] !== 'responses' && !pathSegments[5];
    return isVacancies || isArchive;
  };

  isResponsesActive = (match, location) => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[4] === 'responses' || pathSegments[5] === 'responses';
  };

  logout = () => {
    const { logoutUser } = this.props;
    logoutUser();
  };

  render() {
    const { isOpen, isSettingsOpen } = this.state;
    const {
      name,
      avatar,
      activeTeamShortId,
      hasUnviewedResponse,
    } = this.props;
    const showTitle = !isOpen && window.innerWidth < LARGE_SCREEN_WIDTH;
    return (
      <nav className={isOpen ? 'navigation active' : 'navigation'}>
        <div className={isOpen ? 'menu-wrap active' : 'menu-wrap'}>
          <div className="menu-logo-helper-hidden" />
          <div className="menu-overflow">
            <LinkContainer to="/" onClick={this.closeMenu}>
              <div className="menu-logo" />
            </LinkContainer>
            <ul className="menu">
              <li className="menu-select">
                <span className="menu-select-title">Компания:</span>
                <ActiveTeamToggle onTeamChange={this.closeMenu} />
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to={`/teams/${activeTeamShortId}/vacancies`}
                  isActive={this.isVacanciesActive}
                  title={showTitle ? 'Вакансии' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="bullhorn"
                    />
                  </span>
                  <span className="menu-link-text">Вакансии</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to={`/teams/${activeTeamShortId}/vacancies/responses`}
                  isActive={this.isResponsesActive}
                  title={showTitle ? 'Отклики' : undefined}
                  onClick={this.closeMenu}
                >
                  {hasUnviewedResponse && <span className="menu-link-red-dot" />}
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="check-circle"
                    />
                  </span>
                  <span className="menu-link-text">Отклики</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/report"
                  title={showTitle ? 'Отчётность' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="file-alt"
                    />
                  </span>
                  <span className="menu-link-text">Отчётность</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <span
                  className={isSettingsOpen ? 'menu-link menu-link-is-open' : 'menu-link'}
                  title={showTitle ? 'Настройки' : undefined}
                  onClick={this.settingsToggle}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="cog"
                    />
                  </span>
                  <span className="menu-link-text">Настройки</span>
                  <span className="icon-dropdown">
                    <FontAwesomeIcon
                      className="icon-dropdown-svg"
                      icon="chevron-down"
                    />
                  </span>
                </span>
                <Collapse
                  isOpen={isSettingsOpen}
                >
                  <div className="menu-inner-link-wrap">
                    <NavLink
                      className="menu-inner-link"
                      to={`/teams/${activeTeamShortId}/widget-customization`}
                      onClick={this.closeMenu}
                    >
                      Настройка виджета
                    </NavLink>
                    <NavLink
                      className="menu-inner-link"
                      to={`/teams/${activeTeamShortId}/templates`}
                      onClick={this.closeMenu}
                    >
                      Настройка найма
                    </NavLink>
                    <NavLink
                      className="menu-inner-link"
                      to={`/teams/${activeTeamShortId}/field-sets`}
                      onClick={this.closeMenu}
                    >
                      Настройка полей
                    </NavLink>
                    <NavLink
                      className="menu-inner-link"
                      to={`/teams/${activeTeamShortId}/questions`}
                      onClick={this.closeMenu}
                    >
                      Настройка вопросов
                    </NavLink>
                  </div>
                </Collapse>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/companies"
                  title={showTitle ? 'Компании' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="briefcase"
                    />
                  </span>
                  <span className="menu-link-text">Компании</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/profile"
                  title={showTitle ? 'Профиль' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="user"
                    />
                  </span>
                  <span className="menu-link-text">Профиль</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/tariffs"
                  title={showTitle ? 'Тарифы' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="tag"
                    />
                  </span>
                  <span className="menu-link-text">Тарифы</span>
                </NavLink>
              </li>
              <li className="menu-logout-wrap">
                <span className="icon-wrap" onClick={this.logout}>
                  <FontAwesomeIcon
                    className="item-icon"
                    icon="sign-out-alt"
                  />
                </span>
                <span className="menu-logout" onClick={this.logout}>Выход</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="navigation-panel-top">
          <div className={isOpen ? 'navigation-panel-top-moved active' : 'navigation-panel-top-moved'}>
            <div
              className={isOpen ? 'hamburger hamburger--arrow is-active' : 'hamburger hamburger--arrow'}
              onClick={this.toggleMenu}
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
            <span className="navigation-panel-top-title-company">Компания:</span>
            <div className="navigation-panel-top-company">
              <ActiveTeamToggle onTeamChange={this.closeMenu} />
            </div>
          </div>
          <div className="navigation-panel-top-profile">
            <div className="navigation-panel-top-person">
              <LinkContainer to="/profile" onClick={this.closeMenu}>
                <div className="navigation-panel-top-person-avatar">
                  {avatar && <img src={avatar} alt="avatar" />}
                </div>
              </LinkContainer>
              <LinkContainer to="/profile" onClick={this.closeMenu}>
                <span className="navigation-panel-top-person-name">
                  {name}
                </span>
              </LinkContainer>
            </div>
            <div className="navigation-panel-top-logout">
              <span className="navigation-panel-top-logout-text" onClick={this.logout}>Выход</span>
              <span className="icon-wrap" onClick={this.logout}>
                <FontAwesomeIcon
                  className="item-icon"
                  icon="sign-out-alt"
                />
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

NavigationBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  hasUnviewedResponse: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: { user, activeTeamShortId },
    entities: { users },
    responses: { hasUnviewedResponse = false },
  } = state;
  const { first_name: name, avatar } = users[user];
  return ({
    name,
    avatar,
    activeTeamShortId,
    hasUnviewedResponse,
  });
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(onClickOutside(NavigationBar)),
);
