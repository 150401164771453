import React from 'react';
import PropTypes from 'prop-types';

import QuestionContainer from './QuestionContainer';
import withPagination from '../containers/withPagination';


const QuestionsList = (props) => {
  const { items: questions, deleteQuestion, activeTeam } = props;
  return questions.length
    ? (
      <div className="content-container-list">
        {questions.map(question => (
          <QuestionContainer
            key={question.id}
            question={question}
            deleteQuestion={deleteQuestion}
            activeTeam={activeTeam}
          />
        ))}
      </div>
    )
    : (
      <div className="content-container content-container_padding_sm">
        <div className="content-container__empty">Нет вопросов</div>
      </div>
    );
};

QuestionsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  activeTeam: PropTypes.number.isRequired,
};

export default withPagination(QuestionsList, 'questions');
