import {
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_HIRING_TEMPLATE_FAIL,
  CREATE_HIRING_TEMPLATE_REQUEST,
  CREATE_HIRING_TEMPLATE_SUCCESS,
  CREATE_NEW_TEAM_FAIL,
  CREATE_NEW_TEAM_REQUEST,
  CREATE_NEW_TEAM_SUCCESS,
  CREATE_VACANCY_FAIL,
  CREATE_VACANCY_REQUEST,
  CREATE_VACANCY_SUCCESS,
  DELETE_HIRING_TEMPLATE_FAIL,
  DELETE_HIRING_TEMPLATE_REQUEST,
  DELETE_HIRING_TEMPLATE_SUCCESS,
  DELETE_VACANCY_FAIL,
  DELETE_VACANCY_REQUEST,
  DELETE_VACANCY_SUCCESS,
  EDIT_HIRING_TEMPLATE_FAIL,
  EDIT_HIRING_TEMPLATE_REQUEST,
  EDIT_HIRING_TEMPLATE_SUCCESS,
  EDIT_VACANCY_FAIL,
  EDIT_VACANCY_REQUEST,
  EDIT_VACANCY_SUCCESS,
  GET_ALL_TEAMS_FAIL,
  GET_ALL_TEAMS_REQUEST,
  GET_ALL_TEAMS_SUCCESS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  REMOVE_USER_FROM_TEAM_FAIL,
  REMOVE_USER_FROM_TEAM_REQUEST,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  SEND_INVITE_FAIL,
  SEND_INVITE_REQUEST,
  SEND_INVITE_SUCCESS,
  EDIT_STYLE_REQUEST,
  EDIT_STYLE_SUCCESS,
  EDIT_STYLE_FAIL,
  EDIT_TEAM_NAME_REQUEST,
  EDIT_TEAM_NAME_SUCCESS,
  EDIT_TEAM_NAME_FAIL,
  GET_DEFAULT_STYLE_REQUEST,
  GET_DEFAULT_STYLE_SUCCESS,
  GET_DEFAULT_STYLE_FAIL,
  EDIT_HH_ID_REQUEST,
  EDIT_HH_ID_SUCCESS,
  EDIT_HH_ID_FAIL,
  IMPORT_HH_VACANCIES_REQUEST,
  IMPORT_HH_VACANCIES_SUCCESS,
  IMPORT_HH_VACANCIES_FAIL,
  GET_ACTIVE_TEAM_REQUEST,
  GET_ACTIVE_TEAM_SUCCESS,
  GET_ACTIVE_TEAM_FAIL,
  EDIT_TEAM_URL_FAIL,
  EDIT_TEAM_URL_REQUEST,
  EDIT_TEAM_URL_SUCCESS,
  EDIT_VACANCIES_SUCCESS,
  EDIT_VACANCIES_FAIL,
  EDIT_VACANCIES_REQUEST,
  DELETE_VACANCIES_REQUEST,
  DELETE_VACANCIES_SUCCESS,
  DELETE_VACANCIES_FAIL,
  TOGGLE_ADMIN_RIGHTS_REQUEST,
  TOGGLE_ADMIN_RIGHTS_SUCCESS,
  TOGGLE_ADMIN_RIGHTS_FAIL,
  EDIT_TEAM_CAPTCHA_SUCCESS,
  EDIT_TEAM_CAPTCHA_FAIL,
  EDIT_TEAM_CAPTCHA_REQUEST,
  PUBLISH_VACANCY_RESTRICT,
  DELETE_INVITE_REQUEST,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAIL,
  GET_VACANCIES_FROM_HH_REQUEST,
  GET_VACANCIES_FROM_HH_SUCCESS,
  GET_VACANCIES_FROM_HH_FAIL,
  EDIT_VACANCIES_DISPLAY_SUCCESS,
  EDIT_VACANCIES_DISPLAY_REQUEST,
  EDIT_VACANCIES_DISPLAY_FAIL,
} from '../constants/teamConst';
import axios, { hhAxios } from '../api';
import store from '../store';
import { teamSchema, teamsSchema } from '../schemas';
import history from '../routes/history';
import { changeAndUpdateActiveTeam, loadInitialData } from './app';
import { setAuthorizationToken } from '../utils';


export const loadActiveTeam = () => ({
  normalizeSchema: teamSchema,
  types: [GET_ACTIVE_TEAM_REQUEST, GET_ACTIVE_TEAM_SUCCESS, GET_ACTIVE_TEAM_FAIL],
  callApi: () => {
    const { app: { activeTeam } } = store.getState();
    return axios.get(`/teams/${activeTeam}/`);
  },
});

export const restrictPublishVacancy = vacancyId => ({
  type: PUBLISH_VACANCY_RESTRICT,
  error: { tarifError: 'На бесплатном тарифе можно опубликовать не больше 3 вакансий' },
  vacancy: { id: vacancyId },
});

export const loadAllTeams = () => ({
  name: 'teams',
  normalizeSchema: teamsSchema,
  types: [GET_ALL_TEAMS_REQUEST, GET_ALL_TEAMS_SUCCESS, GET_ALL_TEAMS_FAIL],
  callApi: () => axios.get('/teams_simplified/'),
  payload: { activeTeam: store.getState().app.activeTeam },
});

export const createNewTeam = name => ({
  name: 'team',
  normalizeSchema: teamSchema,
  types: [CREATE_NEW_TEAM_REQUEST, CREATE_NEW_TEAM_SUCCESS, CREATE_NEW_TEAM_FAIL],
  callApi: () => axios.post('/teams/', { name }),
  afterSuccessCall: (response, dispatch) => dispatch(loadInitialData()),
});

export const editVacancies = (vacancies, redirect = true) => ({
  name: 'vacancies',
  types: [EDIT_VACANCIES_REQUEST, EDIT_VACANCIES_SUCCESS, EDIT_VACANCIES_FAIL],
  callApi: () => axios.patch('/vacancies/', vacancies),
  afterSuccessCall: () => {
    if (redirect) {
      const { app: { activeTeamShortId } } = store.getState();
      history.push(`/teams/${activeTeamShortId}/vacancies`);
    }
  },
});

export const deleteVacancies = vacanciesIds => ({
  name: 'vacancies',
  types: [DELETE_VACANCIES_REQUEST, DELETE_VACANCIES_SUCCESS, DELETE_VACANCIES_FAIL],
  callApi: () => {
    const params = new URLSearchParams();
    vacanciesIds.forEach((id) => {
      params.append('ids', id);
    });
    const request = {
      params,
    };
    return axios.delete('/vacancies/', request);
  },
});

export const editLogo = (vacancyId, redirect = true, vacancyImage) => {
  const data = new FormData();
  data.append('logo', vacancyImage);
  return {
    name: 'vacancy',
    types: ['EDIT_VACANCY_REQUEST', 'EDIT_VACANCY_SUCCESS', 'EDIT_VACANCY_FAIL'],
    callApi: () => axios.patch(`/vacancies/${vacancyId}/`, data),
    afterSuccessCall: () => {
      if (redirect) {
        const { app: { activeTeamShortId } } = store.getState();
        history.push(`/teams/${activeTeamShortId}/vacancies`);
      }
    },
  };
};

export const editVacancy = (vacancy, redirect = true, vacancyImage = null) => {
  const { id } = vacancy;
  return {
    name: 'vacancy',
    types: [EDIT_VACANCY_REQUEST, EDIT_VACANCY_SUCCESS, EDIT_VACANCY_FAIL],
    callApi: () => axios.patch(`/vacancies/${id}/`, vacancy),
    payload: { vacancy },
    afterSuccessCall: (response, dispatch) => {
      if ((typeof vacancyImage !== 'string')) {
        dispatch(editLogo(vacancy.id, redirect, vacancyImage));
      } else if (redirect) {
        const { app: { activeTeamShortId } } = store.getState();
        history.push(`/teams/${activeTeamShortId}/vacancies`);
      }
    },
  };
};

export const createVacancy = (vacancy, vacancyImage) => ({
  name: 'vacancy',
  types: [CREATE_VACANCY_REQUEST, CREATE_VACANCY_SUCCESS, CREATE_VACANCY_FAIL],
  callApi: () => axios.post('/vacancies/', vacancy),
  afterSuccessCall: ({ id }, dispatch) => {
    const { app: { activeTeamShortId } } = store.getState();
    if (vacancyImage) {
      dispatch(editLogo(id, true, vacancyImage));
    } else {
      history.push(`/teams/${activeTeamShortId}/vacancies`);
    }
  },
});

export const deleteVacancy = vacancy => ({
  types: [DELETE_VACANCY_REQUEST, DELETE_VACANCY_SUCCESS, DELETE_VACANCY_FAIL],
  callApi: () => axios.delete(`/vacancies/${vacancy.id}/`),
  payload: { vacancy },
});

export const createHiringTemplate = template => ({
  name: 'template',
  types: [CREATE_HIRING_TEMPLATE_REQUEST, CREATE_HIRING_TEMPLATE_SUCCESS, CREATE_HIRING_TEMPLATE_FAIL],
  callApi: () => axios.post('/hiring_templates/', template),
  afterSuccessCall: () => {
    const { app: { activeTeamShortId } } = store.getState();
    history.push(`/teams/${activeTeamShortId}/templates`);
  },
});

export const editHiringTemplate = (template, resetForm) => ({
  name: 'template',
  types: [EDIT_HIRING_TEMPLATE_REQUEST, EDIT_HIRING_TEMPLATE_SUCCESS, EDIT_HIRING_TEMPLATE_FAIL],
  callApi: () => axios.patch(`/hiring_templates/${template.id}/`, template),
  afterSuccessCall: () => resetForm(),
});

export const deleteHiringTemplate = template => ({
  types: [DELETE_HIRING_TEMPLATE_REQUEST, DELETE_HIRING_TEMPLATE_SUCCESS, DELETE_HIRING_TEMPLATE_FAIL],
  callApi: () => axios.delete(`/hiring_templates/${template.id}/`),
  payload: { template },
});

export const editTeamName = (teamId, name) => ({
  name: 'team',
  types: [EDIT_TEAM_NAME_REQUEST, EDIT_TEAM_NAME_SUCCESS, EDIT_TEAM_NAME_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { name }),
});

export const editTeamCaptcha = (teamId, isCaptchaOn) => ({
  name: 'team',
  types: [EDIT_TEAM_CAPTCHA_REQUEST, EDIT_TEAM_CAPTCHA_SUCCESS, EDIT_TEAM_CAPTCHA_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { is_captcha_on: isCaptchaOn }),
});

export const editTeamUrl = (teamId, policy_url) => ({
  name: 'team',
  types: [EDIT_TEAM_URL_REQUEST, EDIT_TEAM_URL_SUCCESS, EDIT_TEAM_URL_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { policy_url }),
});

export const deleteTeam = team => ({
  types: [DELETE_TEAM_REQUEST, DELETE_TEAM_SUCCESS, DELETE_TEAM_FAIL],
  callApi: () => axios.delete(`/teams/${team.id}/`),
  afterSuccessCall: (response, dispatch, getState) => {
    const state = getState();
    const {
      app: { activeTeam },
      entities: { teamsIds },
    } = state;
    if (team.id === activeTeam) {
      dispatch(changeAndUpdateActiveTeam(teamsIds[0]));
    }
  },
  payload: { team },
});

export const editStyle = (teamId, style) => ({
  name: 'team',
  types: [EDIT_STYLE_REQUEST, EDIT_STYLE_SUCCESS, EDIT_STYLE_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { style }),
});

export const showInAllVacancies = (teamId, vacancies_published_at) => ({
  name: 'team',
  types: [EDIT_VACANCIES_DISPLAY_REQUEST, EDIT_VACANCIES_DISPLAY_SUCCESS, EDIT_VACANCIES_DISPLAY_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { vacancies_published_at }),
  withLoading: true,
});

export const getDefaultStyle = () => ({
  name: 'team',
  types: [GET_DEFAULT_STYLE_REQUEST, GET_DEFAULT_STYLE_SUCCESS, GET_DEFAULT_STYLE_FAIL],
  callApi: () => axios.get('/default_style/'),
});

export const sendInvite = (email, teamId) => ({
  types: [SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_FAIL],
  callApi: () => axios.post('/invite/', {
    email,
    team: teamId,
  }),
  payload: { teamId, email },
});

export const deleteInvite = (email, teamId) => ({
  name: 'team',
  payload: { teamId, email },
  types: [DELETE_INVITE_REQUEST, DELETE_INVITE_SUCCESS, DELETE_INVITE_FAIL],
  callApi: () => axios.delete('/invite/', { data: { email, team_id: teamId } }),
});

export const changePassword = (currentPassword, newPassword, reNewPassword) => ({
  types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
  callApi: () => axios.post('/auth/password/', {
    current_password: currentPassword,
    new_password: newPassword,
    re_new_password: reNewPassword,
  }),
});

export const changeEmail = email => ({
  types: [CHANGE_EMAIL_REQUEST, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAIL],
  callApi: () => axios.post('/auth/email/change/', { email }),
});

export const toggleAdminRights = (teamId, userId, isAdmin) => ({
  types: [TOGGLE_ADMIN_RIGHTS_REQUEST, TOGGLE_ADMIN_RIGHTS_SUCCESS, TOGGLE_ADMIN_RIGHTS_FAIL],
  callApi: () => axios.post(`/teams/${teamId}/${isAdmin ? 'admin_delete' : 'admin_add'}/`, { id: userId }),
  payload: { teamId, userId, isAdmin },
});

export const removeUserFromTeam = (teamId, userId) => ({
  types: [REMOVE_USER_FROM_TEAM_REQUEST, REMOVE_USER_FROM_TEAM_SUCCESS, REMOVE_USER_FROM_TEAM_FAIL],
  callApi: () => axios.post(`/teams/${teamId}/user_delete/`, { id: userId }),
  afterSuccessCall: (response, dispatch, getState) => {
    const state = getState();
    const {
      app: { activeTeam },
      entities: { teamsIds },
    } = state;
    if (teamId === activeTeam) {
      const differentTeamId = teamsIds.find(id => teamId !== id);
      dispatch(changeAndUpdateActiveTeam(differentTeamId));
    }
  },
  payload: { teamId, userId },
});

export const editHhId = (teamId, hhEmployerId) => ({
  name: 'team',
  types: [EDIT_HH_ID_REQUEST, EDIT_HH_ID_SUCCESS, EDIT_HH_ID_FAIL],
  callApi: () => axios.patch(`/teams/${teamId}/`, { hh_employer_id: hhEmployerId }),
});

export const importHhVacancies = () => ({
  name: 'vacancies',
  types: [IMPORT_HH_VACANCIES_REQUEST, IMPORT_HH_VACANCIES_SUCCESS, IMPORT_HH_VACANCIES_FAIL],
  callApi: () => axios.post('/vacancies/hh/'),
});

export const getHhVacancies = (teamHhId, teamId, page) => {
  delete hhAxios.defaults.headers.authorization;
  return {
    name: 'vacancies',
    types: [GET_VACANCIES_FROM_HH_REQUEST, GET_VACANCIES_FROM_HH_SUCCESS, GET_VACANCIES_FROM_HH_FAIL],
    callApi: () => hhAxios.get(`https://api.hh.ru/vacancies?employer_id=${teamHhId}&page=${page}`),
    payload: { teamId },
    afterRequestCall: () => {
      setAuthorizationToken(localStorage.getItem('token'));
    },
    withLoading: true,
  };
};
