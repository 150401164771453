import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import dateformat from 'dateformat';

import history from '../routes/history';
import { TRANSLATE_TO_RUSSIAN } from '../utils';
import ConfirmModal from './ConfirmModal';
import ErrorMessage from './ErrorMessage';


class ArchiveItem extends React.Component {
  handleArchive = () => {
    const {
      vacancy: { id, hidden }, editVacancy, toggleSelection, isSelected,
    } = this.props;
    if (isSelected) {
      toggleSelection(id);
    }
    editVacancy({ id, hidden: !hidden, is_published: false }, false);
  }

  handleEdit = () => {
    const {
      vacancy: { id },
      match: { params: { shortId } },
    } = this.props;
    history.push(`/teams/${shortId}/vacancies/${id}`);
  };

  handleDelete = () => {
    const { vacancy, deleteVacancy } = this.props;
    deleteVacancy(vacancy);
  };

  handleSelect = () => {
    const {
      vacancy: { id },
      toggleSelection,
    } = this.props;
    toggleSelection(id);
  };

  render() {
    const {
      vacancy: {
        id,
        title,
        schedule,
        experience,
        publish_date,
        city,
      },
      lastEditFailedVacancy,
      isSelected,
      match: { params: { shortId } },
    } = this.props;
    return (
      <div className={`content-container__item scroll-position-${id}`}>
        <Row className="justify-content-md-between">
          <Col
            sm="9"
            md="10"
            lg="8"
            xl="8"
            className="archive-col-xs-info"
          >
            <div className="container-title-checkbox">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={this.handleSelect}
                  className="checkbox-hidden"
                />
                <span className="checkbox-mark" />
              </label>
              <Link
                className="vacancy-item-title"
                to={`/teams/${shortId}/archive/${id}`}
              >
                {title}
              </Link>
            </div>
            <div className="vacancy-item-description-list">
              <div className="vacancy-item-description-elem vacancy-item-description-elem--publication">
                {dateformat((publish_date).toString(), 'dd.mm.yyyy')}
              </div>
              <div className="vacancy-item-description-elem">
                {city || 'Город не указан'}
              </div>
              <div className="vacancy-item-description-elem">
                {TRANSLATE_TO_RUSSIAN.experiences[experience]}
              </div>
              <div className="vacancy-item-description-elem">
                {TRANSLATE_TO_RUSSIAN.schedules[schedule]}
              </div>
            </div>
            {lastEditFailedVacancy === id && <ErrorMessage errorName="editVacancyError" />}
          </Col>
          <Col
            sm="3"
            md="2"
            xl="4"
            className="vacancy-item-actions-wrap archive-col-xs-buttons"
          >
            <div className="vacancy-item-actions">
              <div className="vacancies-icons-wrap">
                <div className="icon-wrap">
                  <ConfirmModal
                    type="icon"
                    icon="trash-alt"
                    headerText="При удалении вакансии, отклики данной вакансии
                    также будут удалены"
                    modalAction={this.handleDelete}
                    buttonText="Удалить"
                  />
                </div>
                <div className="icon-wrap">
                  <ConfirmModal
                    type="icon"
                    icon="undo"
                    headerText="Перенести вакансию в актуальные?"
                    modalAction={this.handleArchive}
                    buttonText="Перенести"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArchiveItem.defaultProps = {
  lastEditFailedVacancy: null,
};

ArchiveItem.propTypes = {
  vacancy: PropTypes.object.isRequired,
  editVacancy: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  lastEditFailedVacancy: PropTypes.number,
  toggleSelection: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default withRouter(ArchiveItem);
