import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getFormValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import validateFieldForm from './validate/validateFieldForm';
import EntitiesEditContainer from '../containers/EntitiesEditContainer';
import EditField from '../components/EditField';
import { createField, editField, deleteField } from '../actions/fields';
import { TOOLTIP_TEXTS } from '../utils';


class FieldForm extends Component {
  toggleVisibility = () => {
    const { reset } = this.props;
    this.setState(state => ({ isVisible: !state.isVisible }));
    reset();
  };

  handleSubmit = (event) => {
    const { handleSubmit } = this.props;
    handleSubmit(event);
  };

  render() {
    const typeOptions = [
      { label: 'Текст', value: 'text' },
      { label: 'Многострочный текст', value: 'textarea' },
      { label: 'Число', value: 'number' },
      { label: 'Телефон', value: 'tel' },
      { label: 'Email', value: 'email' },
      { label: 'Файл', value: 'file' },
    ];

    const { fieldValues } = this.props;

    return (
      <React.Fragment>
        <div className="create-field-form-container">
          <EntitiesEditContainer
            typeOptions={typeOptions}
            labelName="Название поля"
            newLabelName="Название нового поля"
            fieldValues={fieldValues}
            fieldName="fields"
            ItemComponent={EditField}
            title="Редактирование полей"
            addText="Добавить новое поле"
            filterFunction={value => value.name.includes('custom_field_')}
            onEdit={{ createItem: createField, editItem: editField, deleteItem: deleteField }}
            tooltipText={TOOLTIP_TEXTS.editingField}
          />
        </div>
      </React.Fragment>
    );
  }
}

FieldForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  fieldValues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const fieldValues = getFormValues('createField')(state);
  return {
    fieldValues,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'createField',
    validate: validateFieldForm,
    enableReinitialize: true,
  }),
)(FieldForm);
