import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import withLoading from './withLoading';


const ReportTable = (props) => {
  const {
    report,
    responses,
    hiringTemplatesStates,
    activeTeamShortId,
    startInputDate,
    endInputDate,
    onSort,
    reportType,
  } = props;

  const renderCell = (key, searchParams, counter, itemId) => {
    const vacancyId = (reportType === 'byVacancies' && itemId) ? `/${itemId}/` : '/';

    return (
      <td className="text-center align-middle" key={key}>
        <Link
          to={`teams/${activeTeamShortId}/vacancies${vacancyId}responses/?${searchParams.toString()}`}
        >
          {counter}
        </Link>
      </td>
    );
  };

  function getSearchParams(date_from, date_to, status, id) {
    const searchParams = new URLSearchParams({
      date_from: date_from.toISOString().split('T')[0],
      date_to: date_to.toISOString().split('T')[0],
      status,
    });

    if (id) {
      const fieldName = reportType === 'byVacancies' ? 'vacancy' : 'manager';
      searchParams.append(fieldName, id);
    }

    return searchParams;
  }

  const renderHiringTemplatesRows = hiringTemplatesStates
    .slice(1)
    .map(({ id, name }) => (
      <th
        key={id}
        className="report-table-hiring-title text-center align-middle"
        onClick={() => onSort(name)}
      >
        {name}
      </th>
    ));

  const renderResponseChangesByUserRows = report && report[reportType].map((userData) => {
    const responseStatusChangedRow = Object.keys(userData.hiringCounter).map((hiringName) => {
      const statusId = hiringTemplatesStates.find(item => item.name === hiringName).id;
      const searchParams = getSearchParams(startInputDate, endInputDate, statusId, userData.id);
      return renderCell(hiringName, searchParams, userData.hiringCounter[hiringName], userData.id);
    });

    const searchParams = getSearchParams(startInputDate, endInputDate, 'created', userData.id);
    const createdResponseCounter = renderCell('created', searchParams, userData.created, userData.id);
    const rowTitle = reportType === 'byUsers'
      ? `${userData.first_name} ${userData.last_name}`
      : userData.title;
    return (
      <tr key={userData.id}>
        <th className="report-table-hiring-title text-center align-middle">
          {rowTitle}
        </th>
        {createdResponseCounter}
        {responseStatusChangedRow}
      </tr>
    );
  });

  const newResponsesSumCell = () => {
    const searchParams = getSearchParams(startInputDate, endInputDate, 'created');
    return renderCell('created', searchParams, report.created);
  };

  const hiringTemplateSumsRow = report && hiringTemplatesStates
    .slice(1)
    .map(({ name }) => {
      const searchParams = getSearchParams(
        startInputDate, endInputDate, hiringTemplatesStates.find(item => item.name === name).id,
      );
      return (
        <td className="text-center" key={name}>
          <Link
            to={`teams/${activeTeamShortId}/vacancies/responses/?${searchParams.toString()}`}
          >
            {report.byTemplates[name]}
          </Link>
        </td>
      );
    });

  return (
    responses.length
      ? (
        <div className="content-container report-table-wrap">
          <Table className="teams-table report-table" responsive>
            <thead>
              <tr>
                <th className="report-table-hiring-title text-center align-middle">
                  Статус
                </th>
                <th
                  className="report-table-hiring-title text-center align-middle"
                  onClick={() => onSort('created')}
                >
                  Поступило откликов
                </th>
                {renderHiringTemplatesRows}
              </tr>
            </thead>
            <tbody>
              {renderResponseChangesByUserRows}
              <tr>
                <th className="report-table-hiring-title text-center align-middle">
                  Всего
                </th>
                {report && newResponsesSumCell()}
                {report && hiringTemplateSumsRow}
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="content-container content-container_padding_sm">
          <div className="content-container__empty">За данный период времени отклики не изменялись</div>
        </div>
      )
  );
};

ReportTable.defaultProps = {
  report: null,
};

ReportTable.propTypes = {
  report: PropTypes.object,
  hiringTemplatesStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  startInputDate: PropTypes.object.isRequired,
  endInputDate: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportType: PropTypes.string.isRequired,
};

export default withLoading(ReportTable);
