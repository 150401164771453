import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { reset } from 'redux-form';
import { Helmet } from 'react-helmet';

import FieldSetForm from '../forms/FieldSetForm';
import { createFieldSet, editFieldSet } from '../actions/fieldSets';
import { createField } from '../actions/fields';
import { TRANSLATE_TO_RUSSIAN } from '../utils';
import Breadcrumbs from '../components/Breadcrumbs';


class EditFieldSets extends Component {
  getFormattedFields = () => {
    const { vacancyFields, fieldSet: { fields } } = this.props;
    return fields.map((field) => {
      const vacancyField = vacancyFields.find(({ id }) => id === field.field_id);
      const label = `${vacancyField.placeholder} (тип: ${TRANSLATE_TO_RUSSIAN.vacancyFieldTypes[vacancyField.type]})`;
      return {
        ...field,
        field_id: { label, value: field.field_id },
      };
    });
  };

  getInitialValuesFieldSet = () => {
    const {
      fieldSet: { id, name },
      vacancyFields,
    } = this.props;

    if (id) {
      return { name, fields: this.getFormattedFields() };
    }

    const firstNameField = vacancyFields.find(field => field.name === 'first_name');
    const { placeholder, type, id: value } = firstNameField;
    const label = `${placeholder} (тип: ${TRANSLATE_TO_RUSSIAN.vacancyFieldTypes[type]})`;
    return {
      fields: [
        { field_id: { label, value }, required: true },
      ],
    };
  };

  reverseFormatFieldSet = (fieldSet) => {
    const formattedFields = fieldSet.fields.map(field => ({ ...field, field_id: field.field_id.value }));
    return { ...fieldSet, fields: formattedFields };
  };

  onSubmitCreateFieldSetForm = (values) => {
    const {
      fieldSet: { id },
      editFieldSet,
      createFieldSet,
      resetCreateFieldSetForm,
    } = this.props;
    const formattedFields = this.reverseFormatFieldSet(values);
    if (id) {
      const filteredFormattedFields = { ...formattedFields };
      filteredFormattedFields.fields = formattedFields.fields.map((field) => {
        if (!field.id) {
          const newField = { ...field };
          delete newField.id;
          return newField;
        }
        return field;
      });
      editFieldSet(id, filteredFormattedFields, resetCreateFieldSetForm);
    } else {
      createFieldSet(formattedFields);
    }
  };

  onSubmitCreateFieldForm = (values) => {
    const { createField, resetCreateFieldForm } = this.props;
    createField({ ...values, name: `custom_field_${uuid()}` })
      .then(() => resetCreateFieldForm());
  };

  getInitialValuesOfCustomFields = () => {
    const { vacancyFields } = this.props;
    const customField = vacancyFields.filter(({ name }) => name.includes('custom'));
    return customField.reduce(
      (accum, { id, placeholder }) => ({ ...accum, [`field-name-${id}`]: placeholder }),
      {},
    );
  }

  render() {
    const {
      fieldSet: { id, name },
      vacancyFields,
      activeTeamShortId,
    } = this.props;
    const breadcrumbName = id ? name : 'Создать набор полей';
    const breadcrumbLink = id
      ? `/teams/${activeTeamShortId}/field-sets#${id}`
      : `/teams/${activeTeamShortId}/field-sets`;
    const breadcrumbs = [
      { title: 'Настройка набора полей', link: breadcrumbLink },
      { title: breadcrumbName, link: '' },
    ];

    const initialValuesField = this.getInitialValuesOfCustomFields();
    const initialValuesFieldSet = this.getInitialValuesFieldSet();
    const title = id
      ? `${name} редактирования набора полей | Jobs Widget`
      : 'Создать набор полей | Jobs Widget';
    return (
      <div className="edit-fields-sets">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h2 className="template-editing-title">
          Редактирование набора полей
          {' '}
          <span>{name}</span>
        </h2>
        <FieldSetForm
          initialValues={initialValuesFieldSet}
          vacancyFields={vacancyFields}
          onSubmit={this.onSubmitCreateFieldSetForm}
          onSubmitCreateFieldForm={this.onSubmitCreateFieldForm}
          initialValuesField={initialValuesField}
          activeTeamShortId={activeTeamShortId}
          fieldSetId={id}
        />
      </div>
    );
  }
}

EditFieldSets.defaultProps = {
  fieldSet: {},
  vacancyFields: [],
};

EditFieldSets.propTypes = {
  fieldSet: PropTypes.object,
  vacancyFields: PropTypes.arrayOf(PropTypes.object),
  createFieldSet: PropTypes.func.isRequired,
  editFieldSet: PropTypes.func.isRequired,
  createField: PropTypes.func.isRequired,
  resetCreateFieldForm: PropTypes.func.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  resetCreateFieldSetForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    fieldSets,
    fields: vacancyFields,
    app: { activeTeamShortId },
  } = state;
  const fieldSet = fieldSets.find(({ id }) => +ownProps.match.params.fieldSetId === id);
  return {
    fieldSet,
    vacancyFields,
    activeTeamShortId,
  };
};

const mapDispatchToProps = dispatch => ({
  createFieldSet: fieldSet => dispatch(createFieldSet(fieldSet)),
  editFieldSet: (id, fieldSet, resetForm) => dispatch(editFieldSet(id, fieldSet, resetForm)),
  createField: field => dispatch(createField(field)),
  resetCreateFieldForm: () => dispatch(reset('createField')),
  resetCreateFieldSetForm: () => dispatch(reset('createFieldSet')),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFieldSets);
