import {
  CREATE_HIRING_TEMPLATE_SUCCESS,
  CREATE_NEW_TEAM_SUCCESS,
  CREATE_VACANCY_SUCCESS,
  DELETE_HIRING_TEMPLATE_SUCCESS,
  DELETE_VACANCY_SUCCESS,
  EDIT_HH_ID_SUCCESS,
  EDIT_HIRING_TEMPLATE_SUCCESS,
  EDIT_TEAM_NAME_SUCCESS,
  DELETE_TEAM_SUCCESS,
  EDIT_VACANCY_SUCCESS,
  GET_ACTIVE_TEAM_SUCCESS,
  GET_ALL_TEAMS_SUCCESS,
  IMPORT_HH_VACANCIES_SUCCESS,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  EDIT_STYLE_SUCCESS,
  EDIT_TEAM_URL_SUCCESS,
  SEND_INVITE_SUCCESS,
  EDIT_VACANCIES_SUCCESS,
  DELETE_VACANCIES_SUCCESS,
  TOGGLE_ADMIN_RIGHTS_SUCCESS,
  EDIT_TEAM_CAPTCHA_SUCCESS,
  DELETE_INVITE_SUCCESS,
  GET_VACANCIES_FROM_HH_SUCCESS,
  EDIT_VACANCIES_DISPLAY_SUCCESS,
} from '../constants/teamConst';

import {
  EDIT_HIRING_STATE_SUCCESS,
} from '../constants/hiringTemplateConst';

import {
  CREATE_RESPONSE_SUCCESS,
  DELETE_RESPONSES_SUCCESS,
  DELETE_RESPONSE_SUCCESS,
  MOVE_RESPONSE_SUCCESS,
} from '../constants/responsesConst';

import {
  DELETE_QUESTION_SUCCESS,
} from '../constants/questionsConst';

import {
  DELETE_FIELD_SET_SUCCESS,
} from '../constants/fieldSetConst';
import { createReducer, updateObject } from '../utils/reducerUtils';


const getTeam = (state, id) => state[id];
const updateTeam = (state, id, updates) => ({
  ...state,
  [id]: {
    ...state[id],
    ...updates,
  },
});

const getActiveTeam = (state, action) => {
  const { data: { entities: { teams: activeTeam } } } = action;
  return { ...state, ...activeTeam };
};

const getAllTeams = (state, action) => {
  const {
    activeTeam,
    teams: { entities: { teams } },
  } = action;
  return { ...teams, [activeTeam]: state[activeTeam] || teams[activeTeam] } || {};
};

const createNewTeam = (state, action) => updateObject(
  state,
  {
    [action.team.result]: action.team.entities.teams[action.team.result],
  },
);

const editVacancies = (state, action) => {
  const teamId = action.vacancies[0].team;
  const team = getTeam(state, teamId);
  const editedVacancies = team.vacancies;
  action.vacancies.forEach((changedVacancy) => {
    const index = editedVacancies.findIndex(vacancy => vacancy.id === changedVacancy.id);
    editedVacancies[index] = changedVacancy;
  });
  return updateTeam(state, teamId, { vacancies: editedVacancies });
};

const deleteVacancies = (state, action) => {
  const teamId = action.vacancies[0].team;
  const team = getTeam(state, teamId);
  const vacanciesToDelete = action.vacancies.map(({ id }) => id);

  const filteredVacancy = team.vacancies.filter(({ id }) => (
    !vacanciesToDelete.includes(id)
  ));

  return updateTeam(state, teamId, { vacancies: filteredVacancy });
};

const createVacancy = (state, action) => {
  const team = getTeam(state, action.vacancy.team);
  const updatedVacancies = [...team.vacancies];
  updatedVacancies.unshift(action.vacancy);
  return updateTeam(state, action.vacancy.team, { vacancies: updatedVacancies });
};

const editVacancy = (state, action) => {
  const teamId = action.vacancy.team;
  const team = getTeam(state, teamId);
  const editedVacancies = team.vacancies.map(
    vacancy => (action.vacancy.id === vacancy.id ? action.vacancy : vacancy),
  );
  return updateTeam(state, teamId, { vacancies: editedVacancies });
};

const deleteVacancy = (state, action) => {
  const teamId = action.vacancy.team;
  const team = getTeam(state, teamId);
  const updatedVacancies = [...team.vacancies];
  const deletedVacancyIndex = updatedVacancies.findIndex(
    vacancy => vacancy.id === action.vacancy.id,
  );
  updatedVacancies[deletedVacancyIndex].deleted = true;
  return updateTeam(state, teamId, { vacancies: updatedVacancies });
};

const deleteQuestionFromVacancies = (state, action) => {
  const teamId = action.activeTeam;
  const team = getTeam(state, teamId);
  const updatedVacancies = team.vacancies.map(
    vacancy => (
      { ...vacancy, questions: vacancy.questions.filter(questionId => (action.id !== questionId)) }
    ),
  );
  return updateTeam(state, teamId, { vacancies: updatedVacancies });
};

const editTeamName = (state, action) => updateTeam(
  state,
  action.team.id,
  { name: action.team.name },
);

const editTeamCaptcha = (state, action) => updateTeam(
  state,
  action.team.id,
  { is_captcha_on: action.team.is_captcha_on },
);

const editStyle = (state, action) => updateTeam(
  state,
  action.team.id,
  { style: action.team.style },
);

const createHiringTemplate = (state, action) => updateTeam(
  state,
  action.template.team,
  {
    hiring_templates: [
      ...state[action.template.team].hiring_templates,
      action.template,
    ],
  },
);

const updateTeamTemplates = (state, action) => ({
  ...state,
  [action.hiringTemplatesStates.team_id]: {
    ...state[`${action.hiringTemplatesStates.team_id}`],
    hiring_templates: action.hiringTemplatesStates.templates,
  },
});

const editHiringTemplate = (state, action) => {
  const teamId = action.template.team;
  const team = getTeam(state, teamId);

  const editedTemplates = team.hiring_templates.map(
    template => (action.template.id === template.id ? action.template : template),
  );
  return updateTeam(state, action.template.team, { hiring_templates: editedTemplates });
};

const deleteHiringTemplate = (state, action) => {
  const teamId = action.template.team;
  const team = getTeam(state, teamId);

  const editedTemplates = team.hiring_templates.filter(
    template => (action.template.id !== template.id),
  );
  return updateTeam(state, action.template.team, { hiring_templates: editedTemplates });
};

const removeUserFromTeam = (state, action) => {
  const team = getTeam(state, action.teamId);
  const editedUsers = team.users.filter(
    user => user !== action.userId,
  );
  return updateTeam(state, action.teamId, { users: editedUsers });
};

const toggleAdminRights = (state, action) => {
  const team = getTeam(state, action.teamId);
  const editedAdmins = !action.isAdmin ? [...team.admins, action.userId]
    : team.admins.filter(adminId => adminId !== action.userId);
  return updateTeam(state, action.teamId, { admins: [...editedAdmins] });
};

const addInvitedUser = (state, action) => {
  const team = getTeam(state, action.teamId);
  const { invited_users } = team;
  return updateTeam(state, action.teamId, { invited_users: [...invited_users, action.data.result.id] });
};

const deleteInvitedUser = (state, action) => updateTeam(state, action.teamId,
  {
    invited_users: action.team
      .filter(invite => invite.team === action.teamId)
      .map(invite => invite.id),
  });

const deleteTeam = (state, action) => {
  const teams = { ...state };
  delete teams[action.team.id];
  return teams;
};

const deleteFieldSetFromTeam = (state, action) => {
  const { teamId, fieldSetId } = action;
  const team = getTeam(state, teamId);
  const editedVacancies = team.vacancies.map(
    vacancy => (
      fieldSetId === vacancy.jwfield_set
        ? { ...vacancy, jwfield_set: team.default_field_set }
        : vacancy
    ),
  );
  return updateTeam(state, teamId, { vacancies: editedVacancies });
};

const editHhId = (state, action) => updateTeam(
  state,
  action.team.id,
  { hh_employer_id: action.team.hh_employer_id },
);

const importHhVacancies = (state, action) => updateTeam(
  state,
  action.vacancies && action.vacancies[0].team,
  { vacancies: action.vacancies },
);

const editTeamUrl = (state, action) => updateTeam(
  state,
  action.team.id,
  { policy_url: action.team.policy_url },
);

const onResponsesDeleted = (state, action) => {
  const { vacancyId } = action;
  const [team] = Object.keys(state)
    .map(teamId => state[teamId])
    .filter(team => team.hasOwnProperty('vacancies'));

  const { vacancies, id: teamId } = team;

  vacancyId.forEach((vacancyId) => {
    const editedVacancyId = vacancies.length !== 1
      ? vacancies.findIndex(({ id }) => id === vacancyId)
      : 0;
    vacancies[editedVacancyId].responses_amount -= 1;
  });

  return updateTeam(state, teamId, { vacancies });
};

const OnResponseDeleted = (state, action) => {
  const { vacancyId } = action;
  const [team] = Object.keys(state)
    .map(teamId => state[teamId])
    .filter(team => team.hasOwnProperty('vacancies'));

  const { vacancies, id: teamId } = team;
  const editedVacancyId = vacancies.length !== 1
    ? vacancies.findIndex(({ id }) => id === vacancyId)
    : 0;

  vacancies[editedVacancyId].responses_amount -= 1;

  return updateTeam(state, teamId, { vacancies });
};

const onResponseCreated = (state, action) => {
  const { vacancyId } = action;
  const [team] = Object.keys(state)
    .map(teamId => state[teamId])
    .filter(team => team.hasOwnProperty('vacancies'));

  const { vacancies, id: teamId } = team;
  const editedVacancyId = vacancies.length !== 1
    ? vacancies.findIndex(({ id }) => id === vacancyId)
    : 0;

  vacancies[editedVacancyId].responses_amount += 1;

  return updateTeam(state, teamId, { vacancies });
};

const getHhVacancies = (state, action) => {
  const { teamId, vacancies: { items, page, found } } = action;
  const { [teamId]: team } = state;
  team.hhVacancies = { totalAmount: found, currentPage: page, HhVacancies: [...items] };
  return { ...state, [teamId]: { ...team } };
};

const editVacanciesDisplay = (state, { team }) => {
  const { id, vacancies_published_at } = team;
  const currentTeam = state[id];
  return { ...state, [id]: { ...currentTeam, vacancies_published_at } };
};

const moveResponse = (state, action) => {
  const { sourceVacancyId, data: { vacancy: targetVacancyId } } = action;

  const [activeTeamId] = Object.keys(state).filter(teamId => state[teamId].hasOwnProperty('vacancies'));
  const activeTeam = state[activeTeamId];

  const { vacancies } = activeTeam;
  const [sourceVacancy] = vacancies.filter(({ id }) => id === sourceVacancyId);
  sourceVacancy.responses_amount -= 1;

  const [targetVacancy] = vacancies.filter(({ id }) => id === targetVacancyId);
  targetVacancy.responses_amount += 1;

  return { ...state, [sourceVacancyId]: { ...sourceVacancy }, [targetVacancyId]: { ...targetVacancyId } };
};

const initialState = {};

export default createReducer(initialState, {
  [GET_ACTIVE_TEAM_SUCCESS]: getActiveTeam,
  [GET_ALL_TEAMS_SUCCESS]: getAllTeams,
  [CREATE_NEW_TEAM_SUCCESS]: createNewTeam,
  [EDIT_STYLE_SUCCESS]: editStyle,
  [EDIT_VACANCIES_SUCCESS]: editVacancies,
  [DELETE_VACANCIES_SUCCESS]: deleteVacancies,
  [CREATE_VACANCY_SUCCESS]: createVacancy,
  [EDIT_VACANCY_SUCCESS]: editVacancy,
  [DELETE_VACANCY_SUCCESS]: deleteVacancy,
  [CREATE_HIRING_TEMPLATE_SUCCESS]: createHiringTemplate,
  [EDIT_HIRING_TEMPLATE_SUCCESS]: editHiringTemplate,
  [DELETE_HIRING_TEMPLATE_SUCCESS]: deleteHiringTemplate,
  [REMOVE_USER_FROM_TEAM_SUCCESS]: removeUserFromTeam,
  [SEND_INVITE_SUCCESS]: addInvitedUser,
  [EDIT_TEAM_NAME_SUCCESS]: editTeamName,
  [EDIT_TEAM_CAPTCHA_SUCCESS]: editTeamCaptcha,
  [DELETE_TEAM_SUCCESS]: deleteTeam,
  [DELETE_FIELD_SET_SUCCESS]: deleteFieldSetFromTeam,
  [EDIT_HH_ID_SUCCESS]: editHhId,
  [IMPORT_HH_VACANCIES_SUCCESS]: importHhVacancies,
  [DELETE_QUESTION_SUCCESS]: deleteQuestionFromVacancies,
  [EDIT_TEAM_URL_SUCCESS]: editTeamUrl,
  [TOGGLE_ADMIN_RIGHTS_SUCCESS]: toggleAdminRights,
  [EDIT_HIRING_STATE_SUCCESS]: updateTeamTemplates,
  [DELETE_INVITE_SUCCESS]: deleteInvitedUser,
  [DELETE_RESPONSES_SUCCESS]: onResponsesDeleted,
  [DELETE_RESPONSE_SUCCESS]: OnResponseDeleted,
  [CREATE_RESPONSE_SUCCESS]: onResponseCreated,
  [GET_VACANCIES_FROM_HH_SUCCESS]: getHhVacancies,
  [EDIT_VACANCIES_DISPLAY_SUCCESS]: editVacanciesDisplay,
  [MOVE_RESPONSE_SUCCESS]: moveResponse,
});
