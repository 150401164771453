import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { toast, ToastContainer, Slide } from 'react-toastify';


import NavigationBar from '../containers/NavigationBar';
import VacanciesPage from '../containers/VacanciesPage';
import ArchivePage from '../containers/ArchivePage';
import CreateVacancy from '../containers/CreateVacancy';
import EditVacancy from '../containers/EditVacancy';
import ResponsesPage from '../containers/ResponsesPage';
import ResponseReview from '../containers/ResponseReview';
import CreateResponse from '../containers/CreateResponse';
import ProfilePage from '../containers/ProfilePage';
import TeamsPage from '../containers/TeamsPage';
import EditHiringTemplate from '../containers/EditHiringTemplate';
import HiringTemplatesPage from '../containers/HiringTemplatesPage';
import TariffsPage from '../components/TariffsPage';
import PaymentPage from '../components/PaymentPage';
import Footer from '../components/Footer';
import LicenseAgreement from '../components/LicenseAgreement';
import PaymentSuccessPage from '../containers/PaymentSuccessPage';
import WidgetCustomizationPage from '../containers/WidgetCustomizationPage';
import NotFoundPage from '../components/NotFoundPage';
import FieldSetsPage from '../containers/FieldSetsPage';
import EditFieldSets from '../containers/EditFieldSets';
import QuestionsPage from '../containers/QuestionsPage';
import preloader from '../assets/img/preloader.gif';
import WebSocketInstance, { WSCommands } from '../utils/websocket';
import ReportPage from '../containers/ReportPage';
import ImportHhVacancies from '../components/ImportHhVacancies';


export default class PrivateRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('token') || null,
    };
  }

  componentDidMount() {
    const { token } = this.state;
    if (process.env.NODE_ENV === 'production') {
      if (token) {
        this.webSocketConnect();
      }
    }
  }

  componentDidUpdate() {
    const { token } = this.state;
    const localStorageToken = localStorage.getItem('token');

    if (localStorageToken !== token) {
      this.onTokenUpdate(localStorageToken);
    }
  }

  componentWillUnmount() {
    if (process.env.NODE_ENV === 'production') {
      WebSocketInstance.removeCallbacks([WSCommands.SHOW_MESSAGE]);
    }
  }

  onTokenUpdate = (token) => {
    this.setState({ token }, () => this.webSocketConnect());
  }

  onNewMessage = (data) => {
    const { message, status } = data;
    toast[status](message);
  };

  webSocketConnect = () => {
    WebSocketInstance.connect();
    WebSocketInstance.addCallbacks({ [WSCommands.SHOW_MESSAGE]: this.onNewMessage });
  }

  render() {
    const { dataLoaded, activeTeamShortId } = this.props;
    return (
      <React.Fragment>
        <ToastContainer
          transition={Slide}
          autoClose={15000}
          newestOnTop
          pauseOnFocusLoss
          hideProgressBar
        />
        <NavigationBar />
        <Container className="private-page-container">
          {dataLoaded
            ? (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to={`/teams/${activeTeamShortId}/vacancies`} />}
                />
                <Route
                  path="/teams/:shortId/vacancies"
                  exact
                  component={VacanciesPage}
                />
                <Route
                  path="/teams/:shortId/vacancies/import"
                  exact
                  component={ImportHhVacancies}
                />
                <Route
                  path="/teams/:shortId/archive"
                  exact
                  component={ArchivePage}
                />
                <Route
                  path="/teams/:shortId/create-vacancy"
                  exact
                  component={CreateVacancy}
                />
                <Route
                  path="/teams/:shortId/vacancies/responses"
                  exact
                  component={ResponsesPage}
                />
                <Route
                  path="/teams/:shortId/vacancies/:id"
                  exact
                  component={EditVacancy}
                />
                <Route
                  path="/teams/:shortId/archive/:id"
                  exact
                  render={props => <EditVacancy mode="archive" {...props} />}
                />
                <Route
                  path="/teams/:shortId/vacancies/:vacancyId/responses"
                  exact
                  component={ResponsesPage}
                />
                <Route
                  path="/teams/:shortId/vacancies/:vacancyId/responses/:responseId"
                  exact
                  component={ResponseReview}
                />
                <Route
                  path="/teams/:shortId/vacancies/responses/create-response"
                  exact
                  component={CreateResponse}
                />
                <Route
                  path="/teams/:shortId/widget-customization"
                  exact
                  component={WidgetCustomizationPage}
                />
                <Route
                  path="/companies"
                  exact
                  component={TeamsPage}
                />
                <Route
                  path="/teams/:shortId/templates/"
                  exact
                  component={HiringTemplatesPage}
                />
                <Route
                  path="/teams/:shortId/templates/:templateId"
                  exact
                  component={EditHiringTemplate}
                />
                <Route
                  path="/tariffs"
                  exact
                  component={TariffsPage}
                />
                <Route
                  path="/tariffs/payment"
                  exact
                  component={PaymentPage}
                />
                <Route
                  path="/payment/success"
                  exact
                  component={PaymentSuccessPage}
                />
                <Route
                  path="/profile"
                  exact
                  component={ProfilePage}
                />
                <Route
                  path="/teams/:shortId/field-sets"
                  exact
                  component={FieldSetsPage}
                />
                <Route
                  path="/teams/:shortId/field-sets/:fieldSetId"
                  exact
                  component={EditFieldSets}
                />
                <Route
                  path="/license-agreement"
                  exact
                  component={LicenseAgreement}
                />
                <Route
                  path="/teams/:shortId/questions"
                  exact
                  component={QuestionsPage}
                />
                <Route
                  path="/login"
                  exact
                  render={() => <Redirect to="/" />}
                />
                <Route
                  path="/signup"
                  exact
                  render={() => <Redirect to="/" />}
                />
                <Route
                  path="/report"
                  exact
                  component={ReportPage}
                />
                <Route
                  path="*"
                  component={NotFoundPage}
                />
              </Switch>
            )
            : (
              <div className="loader">
                <img className="loader__gif" src={preloader} alt="Загрузка..." />
              </div>
            )}
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

PrivateRoutes.propTypes = {
  dataLoaded: PropTypes.bool.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
};
