import { createReducer } from '../utils/reducerUtils';
import {
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION_SUCCESS,
  EDIT_QUESTION_SUCCESS,
  GET_QUESTIONS_SUCCESS,
} from '../constants/questionsConst';

import {
  EDIT_VACANCY_SUCCESS,
} from '../constants/teamConst';


const getQuestions = (state, action) => action.questions;

const createQuestion = (state, action) => [...state, action.question];

const editedQuestion = (state, action) => state.map(
  question => (action.question.id === question.id ? action.question : question),
);

const deleteQuestion = (state, action) => [...state].map(
  question => ((action.id === question.id) ? { ...question, deleted: true } : question),
);

const editVacanciesInQuestions = (state, action) => [...state].map((question) => {
  const useInVacanciesKeys = Object.keys(question.useInVacancies).map(key => parseInt(key, 10));
  const isIncludesInQuestion = useInVacanciesKeys.includes(action.vacancy.id);
  const isIncludesInAction = action.vacancy.questions.includes(question.id);
  if (!isIncludesInQuestion && isIncludesInAction) {
    return {
      ...question,
      useInVacancies: { ...question.useInVacancies, [action.vacancy.id]: action.vacancy.title },
    };
  }
  if (isIncludesInQuestion && !isIncludesInAction) {
    const editedUseInVacancies = { ...question.useInVacancies };
    delete editedUseInVacancies[action.vacancy.id];
    return {
      ...question,
      useInVacancies: editedUseInVacancies,
    };
  }
  return question;
});

const initialState = [];

export default createReducer(initialState, {
  [GET_QUESTIONS_SUCCESS]: getQuestions,
  [CREATE_QUESTION_SUCCESS]: createQuestion,
  [EDIT_QUESTION_SUCCESS]: editedQuestion,
  [DELETE_QUESTION_SUCCESS]: deleteQuestion,
  [EDIT_VACANCY_SUCCESS]: editVacanciesInQuestions,
});
