import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalWindow from '../components/ModalWindow';
import { createQuestion, editQuestion } from '../actions/questions';
import QuestionForm from '../forms/QuestionForm';


class QuestionModal extends Component {
  modalWindow = React.createRef();

  componentDidUpdate(prevProps) {
    const { createQuestionStatus, editQuestionStatus } = this.props;
    const isQuestionCreated = prevProps.createQuestionStatus === 'request' && createQuestionStatus === 'success';
    const isQuestionEdited = prevProps.editQuestionStatus === 'request' && editQuestionStatus === 'success';
    if (isQuestionCreated || isQuestionEdited) {
      this.closeModalWindow();
    }
  }

  closeModalWindow = () => {
    this.modalWindow.current.close();
  };

  handleSubmit = (values) => {
    const { createQuestion, editQuestion, question } = this.props;
    if (question && question.id) {
      editQuestion(values);
    } else {
      createQuestion(values);
    }
  };

  render() {
    const {
      modalButton,
      question,
      headerText,
    } = this.props;

    return (
      <ModalWindow
        headerText={headerText}
        modalButton={modalButton}
        ref={this.modalWindow}
      >
        <QuestionForm onSubmit={this.handleSubmit} initialValues={question} />
      </ModalWindow>
    );
  }
}

QuestionModal.defaultProps = {
  createQuestionStatus: '',
  editQuestionStatus: '',
  question: null,
};

QuestionModal.propTypes = {
  createQuestion: PropTypes.func.isRequired,
  editQuestion: PropTypes.func.isRequired,
  createQuestionStatus: PropTypes.string,
  editQuestionStatus: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  modalButton: PropTypes.node.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  createQuestionStatus: state.api.createQuestionStatus,
  editQuestionStatus: state.api.editQuestionStatus,
});

const mapDispatchToProps = dispatch => ({
  createQuestion: question => dispatch(createQuestion(question)),
  editQuestion: question => dispatch(editQuestion(question)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal);
