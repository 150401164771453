import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import StyledSelect from './StyledSelect';
import { specializationOptions } from '../forms/constants/vacancyFormConstants';


class VacanciesPageFilterContainer extends Component {
  constructor(props) {
    super(props);
    const { vacancies, pageName } = this.props;
    const countrySet = new Set();
    const titleSet = new Set();
    const specializationSet = new Set();
    const citySet = new Set();
    vacancies.forEach((vacancy) => {
      if (vacancy.country) {
        countrySet.add(vacancy.country);
      }
      titleSet.add(vacancy.title);
      if (pageName === 'AllVacanciesPage') {
        citySet.add(vacancy.city);
      }
      specializationOptions.forEach(
        spec => spec.value === vacancy.specialization && specializationSet.add(spec),
      );
    });

    this.state = {
      filters: {

        countryFilter: {
          fieldName: 'country',
          options: [...countrySet].map(country => ({
            label: country, value: country,
          })),
          value: '',
          placeholder: 'Страна',
          for: ['VacanciesPage'],
        },

        cityFilter: {
          fieldName: 'city',
          options: (pageName === 'AllVacanciesPage' && [...citySet].map(city => ({
            label: city, value: city,
          }))) || [],
          value: null,
          placeholder: 'Город',
          for: ['VacanciesPage', 'AllVacanciesPage'],
        },

        addressFilter: {
          fieldName: 'address',
          options: [],
          value: null,
          placeholder: 'Адрес офиса',
          for: ['VacanciesPage'],
        },

        titleFilter: {
          fieldName: 'title',
          options: [...titleSet].map(title => ({
            label: title, value: title,
          })),
          value: [],
          placeholder: 'Название вакансии',
          for: ['VacanciesPage', 'AllVacanciesPage'],
        },

        specializationFilter: {
          fieldName: 'specialization',
          options: [...specializationSet].map(specialization => ({
            label: specialization.label, value: specialization.value,
          })),
          value: null,
          placeholder: 'Отрасль',
          for: ['AllVacanciesPage'],
        },

        scheduleFilter: {
          fieldName: 'schedule',
          options: [
            { label: 'Полный день', value: 'fullDay' },
            { label: 'Гибкий график', value: 'flexible' },
            { label: 'Сменный график', value: 'shift' },
            { label: 'Удаленная работа', value: 'remote' },
            { label: 'Вахтовый метод', value: 'flyInFlyOut' },
          ],
          value: null,
          placeholder: 'График',
          for: ['VacanciesPage'],
        },

        experienceFilter: {
          fieldName: 'experience',
          options: [
            { label: 'Не требуется', value: 'noExperience' },
            { label: 'От 1 до 3 лет', value: 'between1And3' },
            { label: 'От 3 до 6 лет', value: 'between3And6' },
            { label: 'Более 6 лет', value: 'moreThan6' },
          ],
          value: null,
          placeholder: 'Опыт работы',
          for: ['VacanciesPage'],
        },
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    const { filteredVacancies, pageName } = this.props;
    const { filters } = this.state;
    const {
      titleFilter: { value: titleValue },
      cityFilter: { value: cityValue },
    } = filters;

    if ((!isEqual(prevProps.filteredVacancies, filteredVacancies)) && (!titleValue.length)) {
      this.filterOptionsUpdate('titleFilter', 'title');

      if ((!cityValue) && (pageName === 'AllVacanciesPage')) {
        this.filterOptionsUpdate('cityFilter', 'city');
      }

      this.setState({ filters });
    }
  };

  filterOptionsUpdate = (filterName, optionName) => {
    const { filters } = this.state;
    const { filteredVacancies } = this.props;

    const optionsSet = new Set();
    filteredVacancies.forEach(vacancy => vacancy[optionName] && optionsSet.add(vacancy[optionName]));
    const newOptions = [...optionsSet].map(option => (
      { label: option, value: option }
    ));
    filters[filterName].options = newOptions;
    this.setState({ filters });
  }

  filterAll = () => {
    const { handleFilterChange, vacancies } = this.props;
    const { filters } = this.state;
    let filteredVacancies = vacancies;
    Object.keys(filters).forEach((filterKey) => {
      filteredVacancies = this.filterField(filteredVacancies, filters[filterKey]);
    });
    handleFilterChange(filteredVacancies);
  };

  filterField = (vacancies, filter) => {
    const { options, fieldName } = filter;
    const filterValue = filter.value && filter.value.value
      ? Array.of(filter.value)
      : filter.value;
    if (filterValue && filterValue.length) {
      const optionValues = options
        .filter(option => filterValue.includes(option))
        .map(option => option.value);
      return vacancies.filter(vacancy => optionValues.includes(vacancy[fieldName]));
    }
    return vacancies;
  };

  onSelectChange = (option, filterKey) => {
    const { filters } = this.state;
    const { vacancies } = this.props;
    const addressSet = new Set();
    const citySet = new Set();
    filters[filterKey].value = option;

    if (filterKey === 'countryFilter') {
      filters.cityFilter.value = null;
      filters.addressFilter.value = null;
      if (option) {
        vacancies.forEach((vacancy) => {
          if (vacancy.country === option.value) {
            if (vacancy.city) {
              citySet.add(vacancy.city);
            }
          }
        });
      }
      filters.cityFilter.options = [...citySet].map(city => ({ label: city, value: city }));
      filters.addressFilter.options = [];
      filters.titleFilter.value = [];
    }
    if (filterKey === 'cityFilter') {
      filters.addressFilter.value = null;
      if (option) {
        vacancies.forEach((vacancy) => {
          if (vacancy.city === option.value && vacancy.address) {
            addressSet.add(vacancy.address);
          }
        });
      }
      filters.addressFilter.options = [...addressSet].map(
        address => ({ label: address, value: address }),
      );
      filters.titleFilter.value = [];
    }
    this.setState({
      filters,
    }, () => {
      this.filterAll();
    });
  };

  getRequiredFilters = (filterItem) => {
    const { filters } = this.state;
    const { pageName } = this.props;
    return filters[filterItem].for.includes(pageName);
  }

  render() {
    const { filters } = this.state;

    const addressFiltersComponents = Object.keys(filters).slice(0, 3)
      .filter(this.getRequiredFilters).map(filterKey => (
        <StyledSelect
          key={filterKey}
          placeholder={filters[filterKey].placeholder}
          options={filters[filterKey].options}
          value={filters[filterKey].value}
          onChange={selectValue => this.onSelectChange(selectValue, filterKey)}
          isDisabled={!filters[filterKey].options.length}
          maxMenuHeight={200}
          isClearable
        />
      ));

    const filtersComponents = Object.keys(filters).slice(3)
      .filter(this.getRequiredFilters).map(filterKey => (
        <StyledSelect
          key={filterKey}
          placeholder={filters[filterKey].placeholder}
          options={filters[filterKey].options}
          value={filters[filterKey].value}
          onChange={selectValue => this.onSelectChange(selectValue, filterKey)}
          maxMenuHeight={200}
          isMulti
        />
      ));
    return (
      <div className="vacancies-page-filter-container">
        <div className="vacancies-filter-address">
          {addressFiltersComponents}
        </div>
        {filtersComponents}
      </div>
    );
  }
}

VacanciesPageFilterContainer.defaultProps = {
  vacancies: [],
  filteredVacancies: [],
};

VacanciesPageFilterContainer.propTypes = {
  vacancies: PropTypes.arrayOf(PropTypes.object),
  handleFilterChange: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  filteredVacancies: PropTypes.arrayOf(PropTypes.object),
};

export default connect()(VacanciesPageFilterContainer);
