import {
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAIL,
  EDIT_QUESTION_REQUEST,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAIL,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
} from '../constants/questionsConst';

import axios from '../api';


export const getQuestions = () => ({
  name: 'questions',
  types: [GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAIL],
  callApi: () => axios.get('/questions/'),
});

export const createQuestion = question => ({
  name: 'question',
  types: [CREATE_QUESTION_REQUEST, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_FAIL],
  callApi: () => axios.post('/questions/', question),
});

export const editQuestion = question => ({
  name: 'question',
  types: [EDIT_QUESTION_REQUEST, EDIT_QUESTION_SUCCESS, EDIT_QUESTION_FAIL],
  callApi: () => axios.patch(`/questions/${question.id}/`, question),
});

export const deleteQuestion = (id, activeTeam) => ({
  types: [DELETE_QUESTION_REQUEST, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAIL],
  callApi: () => axios.delete(`/questions/${id}/`),
  payload: { id, activeTeam },
});
