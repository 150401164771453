import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeAndUpdateActiveTeam } from '../actions/app';
import StyledSelect from '../components/StyledSelect';


class ActiveTeamToggle extends Component {
  handleActiveTeamChange = ({ value: teamId }) => {
    const { teams, activeTeam, changeAndUpdateActiveTeam } = this.props;
    if (teamId && teamId !== activeTeam) {
      const newActiveTeamShortId = teams[teamId].short_id;
      const { pathname } = window.location;
      const newUrl = this.mapOldUrlToActual(pathname, newActiveTeamShortId);
      changeAndUpdateActiveTeam(teamId, newUrl);
      this.onTeamChange();
    }
  };

  mapOldUrlToActual = (pathname, newActiveTeamShortId) => {
    const pathnameList = pathname.split('/').slice(1);
    const firstIdIndex = pathnameList.findIndex(urlPart => urlPart.match(/^[0-9]+$/));
    if (pathnameList.includes('teams')) {
      pathnameList[1] = newActiveTeamShortId;
    }
    const pathnameWithoutIds = firstIdIndex === -1
      ? pathnameList.join('/')
      : pathnameList.slice(0, firstIdIndex).join('/');
    const redirectPathname = `/${pathnameWithoutIds}`;
    return redirectPathname;
  }

  onTeamChange = () => {
    const { onTeamChange } = this.props;
    if (onTeamChange) {
      onTeamChange();
    }
  };

  render() {
    const { activeTeam, teams, teamsIds } = this.props;
    const options = activeTeam
      ? teamsIds.map(teamId => ({ label: teams[teamId].name, value: teamId }))
      : [{ label: 'Нет компании', value: null }];
    const value = options.find(option => option.value === activeTeam);
    return (
      <div className="active-team-toggle-selector">
        <StyledSelect
          options={options}
          value={value}
          isSearchable={false}
          onChange={this.handleActiveTeamChange}
          placeholder="Загрузка..."
          noOptionsMessage={() => 'Компании загружаются'}
        />
      </div>
    );
  }
}

ActiveTeamToggle.defaultProps = {
  onTeamChange: null,
};

ActiveTeamToggle.propTypes = {
  activeTeam: PropTypes.number.isRequired,
  teams: PropTypes.object.isRequired,
  teamsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  changeAndUpdateActiveTeam: PropTypes.func.isRequired,
  onTeamChange: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    app: { activeTeam },
    entities: { teams, teamsIds },
  } = state;
  return ({
    activeTeam,
    teams,
    teamsIds,
  });
};

const mapDispatchToProps = dispatch => ({
  changeAndUpdateActiveTeam: (teamId, lastUrlLocation) => dispatch(changeAndUpdateActiveTeam(teamId, lastUrlLocation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveTeamToggle);
