import axios from '../api';
import {
  GET_HIRING_TEMPLATES_STATES_FAIL,
  GET_HIRING_TEMPLATES_STATES_REQUEST,
  GET_HIRING_TEMPLATES_STATES_SUCCESS,
  CREATE_HIRING_STATE_REQUEST,
  CREATE_HIRING_STATE_FAIL,
  CREATE_HIRING_STATE_SUCCESS,
  EDIT_HIRING_STATE_FAIL,
  EDIT_HIRING_STATE_SUCCESS,
  EDIT_HIRING_STATE_REQUEST,
  DELETE_HIRING_STATE_FAIL,
  DELETE_HIRING_STATE_REQUEST,
  DELETE_HIRING_STATE_SUCCESS,
  MANAGE_HIRING_STATE_FAIL,
} from '../constants/hiringTemplateConst';


// eslint-disable-next-line import/prefer-default-export
export const getHiringTemplatesStates = () => ({
  name: 'hiringTemplatesStates',
  types: [GET_HIRING_TEMPLATES_STATES_REQUEST, GET_HIRING_TEMPLATES_STATES_SUCCESS, GET_HIRING_TEMPLATES_STATES_FAIL],
  callApi: () => axios.get('/hiring_templates/states/'),
});

export const createHiringTemplateState = state => ({
  name: 'hiringTemplatesStates',
  types: [CREATE_HIRING_STATE_REQUEST, CREATE_HIRING_STATE_SUCCESS, CREATE_HIRING_STATE_FAIL],
  payload: { id: state.id },
  callApi: () => axios.post('/hiring_templates/states/', state),
});

export const editHiringTemplateState = state => ({
  name: 'hiringTemplatesStates',
  types: [EDIT_HIRING_STATE_REQUEST, EDIT_HIRING_STATE_SUCCESS, EDIT_HIRING_STATE_FAIL],
  callApi: () => axios.patch('/hiring_templates/states/', state),
  payload: { id: state.id },
});

export const deleteHiringTemplateState = state => ({
  name: 'hiringTemplatesStates',
  types: [DELETE_HIRING_STATE_REQUEST, DELETE_HIRING_STATE_SUCCESS, DELETE_HIRING_STATE_FAIL],
  payload: { id: state.id },
  callApi: () => axios.delete('/hiring_templates/states/', { data: { ...state } }),
});

export const manageHiringStateError = (stateId, error) => ({
  name: 'hiringTemplatesStates',
  type: MANAGE_HIRING_STATE_FAIL,
  error: { error },
});
