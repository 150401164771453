import axios from '../api';
import {
  CREATE_FIELD_SET_FAIL,
  CREATE_FIELD_SET_REQUEST,
  CREATE_FIELD_SET_SUCCESS,
  DELETE_FIELD_SET_FAIL,
  DELETE_FIELD_SET_REQUEST,
  DELETE_FIELD_SET_SUCCESS,
  EDIT_FIELD_SET_FAIL,
  EDIT_FIELD_SET_REQUEST,
  EDIT_FIELD_SET_SUCCESS,
  GET_FIELD_SETS_FAIL,
  GET_FIELD_SETS_REQUEST,
  GET_FIELD_SETS_SUCCESS,
} from '../constants/fieldSetConst';
import history from '../routes/history';
import store from '../store';


export const getFieldSets = () => ({
  name: 'fieldSets',
  types: [GET_FIELD_SETS_REQUEST, GET_FIELD_SETS_SUCCESS, GET_FIELD_SETS_FAIL],
  callApi: () => axios.get('/fieldsets/'),
});

export const createFieldSet = fieldSet => ({
  name: 'fieldSet',
  types: [CREATE_FIELD_SET_REQUEST, CREATE_FIELD_SET_SUCCESS, CREATE_FIELD_SET_FAIL],
  callApi: () => axios.post('/fieldsets/', fieldSet),
  afterSuccessCall: () => {
    const { app: { activeTeamShortId } } = store.getState();
    history.push(`/teams/${activeTeamShortId}/field-sets`);
  },
});

export const editFieldSet = (id, fieldSet, resetForm) => ({
  name: 'fieldSet',
  types: [EDIT_FIELD_SET_REQUEST, EDIT_FIELD_SET_SUCCESS, EDIT_FIELD_SET_FAIL],
  callApi: () => axios.patch(`/fieldsets/${id}/`, fieldSet),
  afterSuccessCall: () => resetForm(),
});

export const deleteFieldSet = (teamId, fieldSetId) => ({
  types: [DELETE_FIELD_SET_REQUEST, DELETE_FIELD_SET_SUCCESS, DELETE_FIELD_SET_FAIL],
  callApi: () => axios.delete(`/fieldsets/${fieldSetId}/`),
  payload: { teamId, fieldSetId },
});
