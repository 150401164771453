import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import dateformat from 'dateformat';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../routes/history';
import { TRANSLATE_TO_RUSSIAN } from '../utils';
import ConfirmModal from './ConfirmModal';
import ErrorMessage from './ErrorMessage';


class VacancyItem extends React.Component {
  handleChange = (checked) => {
    if (checked) {
      const { vacancy: { id }, publishVacancy } = this.props;
      publishVacancy(id);
    } else {
      const { vacancy: { id }, editVacancy } = this.props;
      editVacancy({ id, is_published: false });
    }
  };

  handleArchive = () => {
    const {
      vacancy: { id, hidden }, editVacancy, toggleSelection, isSelected,
    } = this.props;
    if (isSelected) {
      toggleSelection(id);
    }
    editVacancy({ id, hidden: !hidden, is_published: false }, false);
  }

  handleEdit = () => {
    const {
      vacancy: { id },
      match: { params: { shortId } },
    } = this.props;
    history.push(`/teams/${shortId}/vacancies/${id}`);
  };

  handleDelete = () => {
    const { vacancy, deleteVacancy } = this.props;
    deleteVacancy(vacancy);
  };

  handleSelect = () => {
    const {
      vacancy: { id },
      toggleSelection,
    } = this.props;
    toggleSelection(id);
  };

  render() {
    const {
      vacancy: {
        id,
        title,
        schedule,
        experience,
        publish_date,
        responses_amount,
        city,
        is_published,
      },
      lastEditFailedVacancy,
      isSelected,
      match: { params: { shortId } },
    } = this.props;

    return (
      <div className={`content-container__item scroll-position-${id}`}>
        <Row className="justify-content-md-between">
          <Col md="4" xl="4" className="vacancy-item-actions-wrap">
            <div className="vacancy-item-actions">
              <label htmlFor="vacancy-item-switch">
                <div className="vacancy-item-switch">
                  <Switch
                    onChange={this.handleChange}
                    checked={is_published}
                    id="vacancy-item-switch"
                    height={24}
                    width={44}
                    onColor="#26b9a1"
                    onHandleColor="#fff"
                    offColor="#c3c9d0"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  <span className="vacancy-item-switch-title">
                    {is_published ? 'Опубликовано' : 'Не опубликовано'}
                  </span>
                </div>
              </label>
              <div className="vacancies-icons-wrap">
                <div className="icon-wrap">
                  <ConfirmModal
                    icon="archive"
                    type="icon"
                    headerText="Архивировать вакансию?"
                    modalAction={this.handleArchive}
                    buttonText="Архивировать"
                  />
                </div>
                <div className="icon-wrap">
                  <ConfirmModal
                    type="icon"
                    icon="trash-alt"
                    headerText="При удалении вакансии, отклики данной вакансии
                    также будут удалены. Для сохранения откликов необходимо отправить
                    вакансию в архив."
                    modalAction={this.handleDelete}
                    buttonText="Удалить"
                  />
                </div>
                <div className="icon-wrap">
                  <FontAwesomeIcon
                    onClick={this.handleEdit}
                    className="item-icon"
                    icon="pencil-alt"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md="7" lg="8" xl="8">
            <div className="container-title-checkbox">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={this.handleSelect}
                  className="checkbox-hidden"
                />
                <span className="checkbox-mark" />
              </label>
              <Link
                className="vacancy-item-title"
                to={`/teams/${shortId}/vacancies/${id}`}
              >
                {title}
              </Link>
            </div>
            <div className="vacancy-item-description-list">
              <div className="vacancy-item-description-elem vacancy-item-description-elem--publication">
                {dateformat((publish_date).toString(), 'dd.mm.yyyy')}
              </div>
              <div className="vacancy-item-description-elem">
                {city || 'Город не указан'}
              </div>
              <div className="vacancy-item-description-elem">
                {TRANSLATE_TO_RUSSIAN.experiences[experience]}
              </div>
              <div className="vacancy-item-description-elem">
                {TRANSLATE_TO_RUSSIAN.schedules[schedule]}
              </div>
            </div>
            <div className="vacancy-item-description-list">
              {responses_amount
                ? (
                  <Link
                    className="vacancy-item-description-elem light-green"
                    to={`/teams/${shortId}/vacancies/${id}/responses/`}
                  >
                    Откликов -
                    {' '}
                    {responses_amount}
                  </Link>
                )
                : (
                  <div className="vacancy-item-description-elem">
                    Откликов -
                    {' '}
                    {responses_amount}
                  </div>
                )
                }
            </div>
            {lastEditFailedVacancy === id && <ErrorMessage errorName="editVacancyError" />}
          </Col>
        </Row>
      </div>
    );
  }
}

VacancyItem.defaultProps = {
  lastEditFailedVacancy: null,
};

VacancyItem.propTypes = {
  vacancy: PropTypes.object.isRequired,
  editVacancy: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  lastEditFailedVacancy: PropTypes.number,
  toggleSelection: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  publishVacancy: PropTypes.func.isRequired,
};

export default withRouter(VacancyItem);
