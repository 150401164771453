import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  formValueSelector,
  isDirty,
  stopSubmit,
  FieldArray,
} from 'redux-form';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, Prompt } from 'react-router-dom';

import ErrorMessage from '../components/ErrorMessage';
import renderField from './render/renderField';
import renderSelect from './render/renderSelect';
import renderFileField from './render/renderFileField';
import renderQuestionsField from './render/renderQuestionsField';
import normalizePhone from './normalize';
import {
  email,
  matchPattern,
  maxLength,
  phone,
  required,
  resume,
} from './validators';
import renderCheckbox from './render/renderCheckbox';


const maxLength10000 = maxLength(10000);
const maxLength200 = maxLength(200);
const matchPatternNumber = matchPattern(/^[ 0-9]+$/, 'Можно вводить только числа');

class CreateResponseForm extends React.Component {
  constructor(props) {
    super(props);
    const { isAllVacancyPage, vacancies } = this.props;
    this.state = {
      vacancy: isAllVacancyPage ? { ...vacancies[0] } : null,
    };
  }

  onChangeVacancy = (event) => {
    this.setState({ vacancy: event.value });
  };

  validateResponseForm = (fieldType) => {
    switch (fieldType) {
      case 'tel': return phone;
      case 'email': return email;
      case 'textarea': return maxLength10000;
      case 'file': return resume;
      case 'number': return matchPatternNumber;
      default: return maxLength200;
    }
  };

  getFields = (vacancy) => {
    const { fields } = vacancy;

    const result = [];
    fields.forEach((field) => {
      result.push((
        <Field
          key={field.name}
          type={field.type}
          name={field.type === 'file' ? `userFile_${field.name}` : field.name}
          component={field.type !== 'file' ? renderField : renderFileField}
          label={field.type !== 'file' ? field.placeholder
            : `Прикрепить ${field.placeholder && field.placeholder.toLowerCase()}`}
          validate={field.required ? [this.validateResponseForm(field.type), required]
            : this.validateResponseForm(field.type)}
          rows="10"
          normalize={field.name === 'phone' ? normalizePhone : undefined}
          accept={field.type === 'file' ? '.pdf, .doc, .docx' : undefined}
          info={field.type === 'file' ? 'Допустимы файлы в формате .pdf, .doc, .docx, размером не более 10 МБ.'
            : undefined}
        />
      ));
    });

    return result;
  };

  render() {
    const {
      handleSubmit,
      submitting,
      dirty,
      submitSucceeded,
      submitFailed,
      activeTeamShortId,
      vacancies,
      questions,
      isAllVacancyPage,
    } = this.props;
    const { vacancy } = this.state;

    const cancelUrl = isAllVacancyPage ? '/all-vacancies'
      : `/teams/${activeTeamShortId}/vacancies/responses`;

    const activeVacancies = vacancies.filter(({ hidden }) => !hidden);

    return (
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="createResponse">
        <Prompt
          when={dirty && !submitSucceeded}
          message="Изменения отклика не сохранены. Вы уверены, что хотите покинуть страницу?"
        />
        {!isAllVacancyPage && (
          <div className="response-form__field">
            <Field
              name="vacancy"
              key="vacancy"
              component={renderSelect}
              label="Вакансия"
              options={activeVacancies.map(vacancy => ({ label: vacancy.title, value: vacancy }))}
              onChange={this.onChangeVacancy}
              defaultValue={vacancy}
            />
          </div>
        )}
        {(vacancy) && (
          <React.Fragment>
            <div className="response-form__field">
              {this.getFields(vacancy)}
            </div>
            <FieldArray
              name="questions"
              component={renderQuestionsField}
              vacancy={vacancy}
              questions={questions}
            />
            <div className="response-form__field">
              <Field
                name="acceptRights"
                type="checkbox"
                label={
                  (
                    <Link
                      className="hrs-check-box-confirmation-link"
                      to="/contest-to-personal-data-processing/" target="_blank"
                    >
                      Я даю своё согласие на обработку персональных данных
                    </Link>
                  )
                }
                classNames="ml-0"
                component={renderCheckbox}
                validate={[required]}
              />
            </div>
          </React.Fragment>
        )}
        <div className="buttons-container">
          <button
            className="button"
            type="submit"
            disabled={submitting || !vacancy}
          >
            Создать
          </button>
          <LinkContainer
            to={cancelUrl}
          >
            <button type="button" className="button button_color_dark-blue">Отмена</button>
          </LinkContainer>
        </div>
        {submitFailed && <div className="form-error">Не все поля заполнены корректно</div>}
        <ErrorMessage errorName="createResponseError" />
      </form>
    );
  }
}

CreateResponseForm.defaultProps = {
  vacancies: [],
  activeTeamShortId: '',
};

CreateResponseForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  activeTeamShortId: PropTypes.string,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  isAllVacancyPage: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { app: { activeTeamShortId } } = state;
  const selector = formValueSelector('createResponse');
  const dirty = isDirty('createResponse')(state);
  const questionsData = selector(state, 'questions') || [];
  return {
    dirty,
    questionsData,
    activeTeamShortId,
  };
};


export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'createResponse',
    onChange: (values, dispatch, props) => {
      if (props.submitFailed) {
        dispatch(stopSubmit('createResponse'));
      }
    },
  }),
)(CreateResponseForm);
