import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { deleteQuestion } from '../actions/questions';
import QuestionsList from '../components/QuestionsList';
import QuestionModal from './QuestionModal';
import Tooltip from '../components/Tooltip';
import { TOOLTIP_TEXTS } from '../utils';


const QuestionsPage = (props) => {
  const {
    deleteQuestion,
    questions,
    location,
    activeTeam,
  } = props;

  const modalButton = (
    <button type="button" className="button button_plus">
      <span className="button-icon">+</span>
      <span className="button-text">Создать вопрос</span>
    </button>
  );

  const notDeletedQuestions = questions.filter(question => question.deleted === null);

  return (
    <div className="section-grey">
      <Helmet>
        <title>Настройка вопросов | Jobs Widget</title>
      </Helmet>
      <div className="page-header-container">
        <h1 className="main-page-title">
          Вопросы
          <Tooltip className="main-page-title__tooltip" text={TOOLTIP_TEXTS.questionsPage} />
        </h1>
        <QuestionModal modalButton={modalButton} headerText="Создать вопрос" />
      </div>
      <QuestionsList
        items={notDeletedQuestions}
        deleteQuestion={deleteQuestion}
        location={location}
        activeTeam={activeTeam}
      />
    </div>
  );
};

QuestionsPage.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  activeTeam: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  questions: state.questions,
  activeTeam: state.app.activeTeam,
});

const mapDispatchToProps = dispatch => ({
  deleteQuestion: (id, activeTeam) => dispatch(deleteQuestion(id, activeTeam)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPage);
